import { Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";

export const BalanceTubs = ({ active, setActive, tubOptions }) => {
  const { windowWidth, userId } = useLadyService();
  
  const Tub = ({ item }) => {
    const {
      type,
      commission,
      commissionText,
      iconPath,
      title,
      creditType,
      mobileTitle,
    } = item;

    return (
      <label
        htmlFor={`pay-${type}`}
        className={`registration__radio${active === type ? " _active" : ""}`}
        onClick={() => setActive(type)}
      >
        <span className="d-flex align-center">
          <Icon
            size={"m"}
            spritePath={iconPath}
            clazz={`mr-4 ${active === type ? "color-green" : ""} topup-icon`}
          />
          {windowWidth < 778 ? mobileTitle : title}
        </span>
        {windowWidth > 998 && creditType}
        <input
          id={`pay-${type}`}
          type="radio"
          value={type}
          defaultChecked={active === type}
        />
        <p className={"p1 color-green"}>
          {!!commission ? commissionText +  " - " + commission + " %" : commissionText}
        </p>
      </label>
    );
  };

  return (
    <div className="registration__radio-group topup">
        {userId === 94 ? tubOptions.map((item) => {
          return <Tub item={item} />;
        }) : tubOptions.slice(1).map((item) => {
          return <Tub item={item} />;
        })}
      {/* {tubOptions.map((item) => {
        if(userId === "148") 
        return <Tub item={item} />;
      })} */}
    </div>
  );
};
