import {Helmet} from "react-helmet";
import React from "react";
import {Button, CheckboxNavigation, Icon, InputNick} from "../ui";
import {useDispatch, useSelector} from "react-redux";
import ShortFilterData from "./ShortFilterData";
import useLadyService from "@/services/LadyService";
import {
    hideShort,
    show,
    showShortFilter,
} from "@/stores/slices/showFilterSlice";

import "./shortFilter.scss"
const ShortFilter = () => {
    const { t } = useLadyService()
    const dispatch = useDispatch();
    const shortFilterOpen = useSelector(showShortFilter);

    return(
        <>
            {!!shortFilterOpen ? (
                <Helmet>
                    <html className={"lock"}></html>
                </Helmet>
            ) : null}
            <form className={`short-filter${shortFilterOpen ? " _active" : ""}`}>
                <Button onClick={() => dispatch(hideShort())} clazz={"filter__close"}>
                    <Icon size={"l"} spritePath={"close"} />
                </Button>
                <div className="short-filter__body">
                    <InputNick
                        type="search"
                        placeholder={t("modelname")}
                        id="headerSearch"
                        clazz={"search__input"}
                        name={"nickname"}
                    />

                    <div className="short-filter__group">
                        {ShortFilterData().options.map((i) => {
                            return (
                                <CheckboxNavigation
                                    key={i.id}
                                    id={i.id}
                                    checked={i.checked}
                                    title={i.title}
                                    path={i.path}
                                    value={i.value}
                                    name={i.name}
                                    filter_disabled={i.forDisabled}
                                    isLink={i.isLink}
                                    translateKey={i.translateKey}
                                />
                            );
                        })}
                    </div>

                    <Button
                        size={"l"}
                        clazz={"button--tetriary short-filter__button"}
                        onClick={() => dispatch(show())}
                    >
                        {t("allfilters")}
                        <Icon size={"l"} spritePath={"filter"} />
                    </Button>
                </div>
                {/* <div className="short-filter__box">
          <Button
            onClick={() => {
              dispatch(hideShort());
              dispatch(setSortedModelsByFilter(filtredString.slice(1)));
              // dispatch(setFilterMiniPopupStatus(true));
            }}
            size={"m"}
            clazz="button--green"
            type="submit"
          >
            {`${t("show")} ${searchCount} ${plural("ru", searchCount)}`}
          </Button>
        </div> */}
            </form>
        </>
    )
}

export default ShortFilter