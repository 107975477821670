import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import {  useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserCurrentCity } from "../../../stores/slices/userSlice";
import { ButtonLink } from "../../ui";
import { Helmet } from "react-helmet";
import useTitle from "@/hooks/useTitle";
import useLadyService from "@/services/LadyService";
import { getSelections, cleanSorted, setArrSorted } from "../../../stores/slices/modelsState";
import {setTitle} from "../../../stores/slices/titleSlice";

const Selection = () => {

  const {t, navigate, lang, dispatch, scrollToTop} = useLadyService()

  const city = useSelector(getUserCurrentCity);
  const defaultSelections = useSelector(getSelections)
  const [data, setData] = useState(defaultSelections);

  const { citySlug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `selections/${citySlug}`;
        const method = "GET";

        const result = await makeRequest({ route, method });
        if (!result) {
          navigate("/404");
        }

        if (result) {
          setData(result);
        }
      } catch (error) {}
    };

    fetchData();
  }, [citySlug]);

  useEffect(() => {
    scrollToTop()
  }, []);

  const postCity = t("incity").replace(/%([^%]+)%/g, city.title ?? "");

  useTitle(t("selectiontitle") + " " + postCity);

  return (
    <>
      <Helmet>
        <meta name="description" content={t("selectdesc") + " " + postCity} />
      </Helmet>
      <main>
        <section className="navigation__container">
          <h1>{t("selectionmodels") + " " + postCity}</h1>
          <div className="d-flex gap-12 flex-wrap">
            {!!data?.length &&
              data.map((item) => {
                const segments = item.split("/");
                const lastSegment = segments[segments.length - 1];
                const title = lastSegment
                  .split("-")
                  .map((str) => {
                    switch (str) {
                      case "elitelady":
                        return "elite";
                      case "vchat":
                        return "videochat";
                      case "ero":
                        return "erocontent";
                      case "booking":
                        return "onlineorder";
                      default:
                        return str;
                    }
                  })
                  .map((result) => t(result));

                return (
                  <ButtonLink
                    href={`/${lang}/${citySlug}/${lastSegment}`}
                    title={title.join(", ")}
                    size={"xs"}
                    clazz={"button_outline--black _no-underline"}
                    onClick={() => {
                      dispatch(setArrSorted(lastSegment.split("-")))
                      title.map(val => dispatch(setTitle(val)))
                    }
                  }
                  >
                    {title.join(", ")}
                  </ButtonLink>
                );
              })}
          </div>
        </section>
      </main>
    </>
  );
};
export default Selection;
