import {Popup} from "../index";
import useLadyService from "../../../services/LadyService";
import {Button, InputInLabel, Toggle} from "../../ui";
import {useSelector} from "react-redux";
import {getEditOperatorStatus, setEditOperatorStatus} from "../../../stores/slices/popupSlice";

const EditOperatorPopup = () => {
  const {dispatch, t, lang, resultBalance, userType, windowWidth} = useLadyService();

  const open = useSelector(getEditOperatorStatus)

  const dataList = [
    {title: t("agencyprofileman")},
    {title: t("adsamanage")},
    {title: t("promoandads")},
    {title: t("reviewmanage")},
    {title: t("partiesmanage")},
    {title: t("statsaccess")},
    {title: t("balancemanage")},
  ]

  const dataListSecond = [
    {title: t("videochats"), subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`},
    {title: t("bookings"), subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`},
    {title: t("gotopreviews"), subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`},
  ]

  return (
    <Popup
      open={open}
      setOpen={() => dispatch(setEditOperatorStatus(false))}
      id={"editOperator"}
      clazz={'popup-form__body_large popup-form--operator gap-24'}
    >
      <h2 className="m-0 mt-16 p-0-28">
        {t("editaddoperator")}
      </h2>

      <div className="popup-form__inner popup-form--operator__grid-inputs">
        <InputInLabel
          // clazz={`${errors.discount ? " error" : ""}`}
          // type={"number"}
          id={"operatorName"}
          autocomplete={'name'}
          // required={isReqDiscount}
          placeholder={"Valentin"}
          // onChange={(e) => handleChangeDis(e)}
          // register={{ ...register("discount") }}
          // value={!!happyHours ? happyHours?.discount : ""}
        >
          {t("names")}
          {/*{(errors.discount && (*/}
          {/*  <span className={"input-label__error"}>*/}
          {/*          {errors.discount.message}*/}
          {/*        </span>*/}
          {/*)) || <span>{t("discountamount")}</span>}*/}
        </InputInLabel>

        <InputInLabel
          id={"operatorEmail"}
          type={"email"}
          autocomplete={'email'}
          placeholder={"valentin-mang@gmail.com"}
        >
          Email
        </InputInLabel>

        <InputInLabel
          id={"operatorPassword"}
          type={"password"}
          autocomplete={'password'}
          placeholder={t("enterpassword")}
          title={t("pass")}
        >
        </InputInLabel>

        <Button
          size={"l-forever"}
          clazz={"button--secondary"}
        >
          {t("generateaccess")}
        </Button>
      </div>

      <h3 className={'m-0 text-left'}>{t("setrightsoper")}</h3>

      <div className="popup-form--operator__grid-toggles">
        <div className="popup-form__inner gap-12">
          {dataList.map((i, index) => (
              <div className="d-flex gap-12 align-center justify-sb">
                <span className={'color-main'}>{i.title}</span>

                <Toggle words={false}/>
              </div>
            )
          )}
        </div>

        <div className="popup-form__inner gap-12">
        {dataListSecond.map((i, index) => (
            <div className="d-flex gap-12 align-center justify-sb">
              <div className="d-flex gap-6 fd-column">
                <span className={'color-main'}>{i.title}</span>
                <span className={'color-600 p3'}>{i.subTitle}</span>
              </div>

              <Toggle words={false}/>
            </div>
          )
        )}
        </div>
      </div>

      <div className="popup-form--operator__buttons">
        <Button
          size={"l-forever"}
          clazz={"button--secondary"}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          clazz={"button--green w-100"}
          buttonType={"submit"}
        >
          {t("Сохранить")}
        </Button>
      </div>
    </Popup>
  );
};

export default EditOperatorPopup;
