import {
  Breadcrumbs,
  Button,
  ButtonLink,
  Icon,
  Note,
  RatingStars,
} from "@/components/ui";
import { useSelector } from "react-redux";
import {
  setOrderModelStatus,
  setStoriesStatus,
  setStoriesMap,
  setVideoChatMode,
  setAuthorizationStatus,
  setMapMode,
  getMapStatus,
  setAuthorisationAccessType,
  setAddStoriesStatus,
} from "@/stores/slices/popupSlice";
import { Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import useLadyService from "@/services/LadyService";
import ModelFavoriteBtn from "../favoriteButton/ModelFavoriteBtn";
import statusElite from "@/assets/img/status/elite.svg";
import statusEliteMob from "@/assets/img/status/elite-mob.svg";
import statusTop from "@/assets/img/status/top.svg";
import statusTopLight from "@/assets/img/status/top.svg";
import statusTopMob from "@/assets/img/status/top-mob.svg";
import statusTopMobLight from "@/assets/img/status/top-mob-light.svg";

import StoriesCircle from "../../../../stories/circle/StoriesCircle";
import "../../../../sliders/newGrilsSlider/newGirlsSlider.scss";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { setLadyForCall } from "@/stores/slices/videoCallSlice";
import React from "react";
import EditBlock from "../editBlock/EditBlock";

const ModelHeader = ({ props, toggleMapOpen, edit, setIsOpenEditMain }) => {
  const {
    name,
    note,
    age,
    rating,
    reviews_count,
    tag_booking,
    ready_videochat,
    is_top,
    is_favorite,
    last_activity,
    is_elite,
    phone,
    telegram,
    whatsapp,
    city_name,
    city_slug,
    address,
    distance,
    stories,
    profileSlug,
    oneProfileData,
  } = props;

  const videoRes = {
    profile: {
      name: oneProfileData.name,
      slug: oneProfileData.slug,
      photo: !!oneProfileData?.media?.photo?.length
        ? [oneProfileData?.media?.photo[0]]
        : null,
    },
  };

  const {
    dispatch,
    theme,
    windowWidth,
    userType,
    getTimeAgo,
    setMediaSize,
    isOnline,
      lang,
  } = useLadyService();

  const { t } = useTranslation("translation");

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  const mapPopupStatus = useSelector(getMapStatus);

  const breadcrumbsList = [
    {
      id: 1,
      title: t("mainpage"),
      link: "/",
    },
    {
      id: 2,
      title: city_name,
      link: `/${lang}/${city_slug}`,
    },
    {
      id: 3,
      title: name,
      link: "",
    },
  ];

  const getStatusImg = () => {
    switch (theme) {
      case "light":
        return !!is_top ? statusTopMobLight : statusElite

      default:
        return !!is_top ? statusTopMob : statusElite;
    }
  };

  const getStatusImgMob = () => {
    switch (theme) {
      case "light":
        return !!is_top ? statusTopLight : statusEliteMob

      default:
        return !!is_top ? statusTop : statusEliteMob;
    }
  };

  return (
    <section className="model-header">
      {edit ? (
        <Link to={"/lk/indi/profiles"} className={"model-header__back"}>
          <Icon size={"m"} spritePath={"chevrone-left"}/>{" "}
          <span>{t("toads")}</span>
        </Link>
      ) : (
        <Breadcrumbs data={breadcrumbsList} clazz={"model-header__breadcrumbs"} />
      )}

      <div
        className={`model-header__body${!!stories?.length || edit ? "" : " no-stories"}`}
      >

        {(!!stories?.length || edit) &&
          <StoriesCircle
            clazz="model-header__stories"
            onClick={() => edit ?
              dispatch(setAddStoriesStatus(true)) :  dispatch(setStoriesStatus(true))
          }
            img={!!stories?.length && setMediaSize(stories[0].media, "xs")}
            edit={edit}
          />
        }

        <div className="model-header__box">
          {(!!is_top || !!is_elite) && (
            <picture className="model-header__status">
              <source
                srcSet={getStatusImg()}
                media="(min-width: 767.98px)"
              />
              <img
                src={getStatusImgMob()}
                alt=""
              />
            </picture>
          )}

          <div className="model-header__interactions">
            <Note note={note} profileId={profileSlug} />

            <ModelFavoriteBtn is_favorite={is_favorite} slug={profileSlug} />
          </div>

          <div className={`model-header__box-info${edit ? ' _edit' : ''}`}>
            <h1 className="model__name text-dots">{name}</h1>
            <div className="model-header__age">
              <span>{age}</span> {t("years")}
            </div>
            {edit &&
              <EditBlock
                btnTitle={t("edit")}
                mb={0}
                onClick={() => setIsOpenEditMain(true)}
              />
            }
          </div>
        </div>

        <div className="model-header__bottom">
          {edit ? null : (
            <span
              className={`model-header__last-visit p3${
                isOnline(last_activity) ? " _online" : " _offline"
              }`}
            >
            {getTimeAgo(last_activity)}
          </span>
          )}

          {!!reviews_count &&  (
            <HashLink
              to={"#reviews"}
              title={t("readreview")}
              className="model-header__review model__review"
            >
            <RatingStars color={'gold'} value={rating} readOnly={true} />
              <span>
                {reviews_count} {t("reviewses")}
              </span>
            </HashLink>
          )}

          {!!distance && !edit ? (
            <span className="model-header__distance p3">
              <Icon spritePath={"location"} size={"s"} />
              {distance} {t("kmsfromyou")}
            </span>
          ) : null}

          {!!address ? (
            <Link
              to={"#"}
              title={t("viewonmap")}
              className="model-header__address text-underline p3 text-dots"
              onClick={() => toggleMapOpen(profileSlug)}
            >
              <Icon spritePath={"map"} size={"s"} />
              {city_name}, {address}
            </Link>
          ) : !!city_name ? (
            <span className="model-header__address p3">
              <Icon spritePath={"map-pin"} size={"s"} />
              {city_name}
            </span>
          ) : null}
        </div>

        <div className={`model__buttons`}>
          {!!tag_booking && !(userType === "indi" || userType === "agency") ? (
            <Button
              title={t("order")}
              onClick={() => {
                if (userType === "default") {
                  dispatch(setAuthorizationStatus(true));
                  dispatch(setAuthorisationAccessType("order"));
                } else {
                  dispatch(setOrderModelStatus(true));
                }
              }}
              size={"m"}
              clazz={"button_outline--green"}
            >
              <Icon spritePath={"pay-content"} size={"l"} />
              {t("order")}
            </Button>
          ) : null}

          {!!ready_videochat &&
          !(userType === "indi" || userType === "agency") ? (
            <Button
              onClick={() => {
                if (userType !== "default") {
                  console.log("3");
                  dispatch(setLadyForCall(videoRes));
                } else {
                  dispatch(setVideoChatMode(true));
                  dispatch(setAuthorizationStatus(true));
                }
                if (mapPopupStatus) {
                  dispatch(setMapMode(true));
                  dispatch(setStoriesMap(false));
                }
              }}
              clazz={`button--green`}
              size={"m"}
              square={
                windowWidth < 767.98 || (windowWidth < 1000.98 && !!tag_booking)
                  ? true
                  : null
              }
            >
              {windowWidth < 767.98 ||
              (windowWidth < 1000.98 && !!tag_booking) ? (
                <Icon size={"xl"} spritePath={"video"} />
              ) : (
                <>
                  <Icon size={"l"} spritePath={"video"} />
                  {t("videochat")}
                </>
              )}
            </Button>
          ) : null}

          {!!phone && (
            <ButtonLink
              href={`tel:${phone}`}
              size={"m"}
              clazz={`button--primary td-none`}
              square={
                (windowWidth < 1199.98 && ready_videochat && tag_booking) ||
                (windowWidth < 476.98 && (ready_videochat || tag_booking))
              }
            >
              {(windowWidth < 1199.98 && ready_videochat && tag_booking) ||
              (windowWidth < 476.98 && (ready_videochat || tag_booking)) ? (
                <Icon size={"xl"} spritePath={"call"} />
              ) : (
                phoneRes
              )}
            </ButtonLink>
          )}

          {!!whatsapp ? (
            <ButtonLink
              href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
              clazz={"button--primary"}
              square={true}
              size={"m"}
              target={"_blank"}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </ButtonLink>
          ) : null}

          {!!telegram ? (
            <ButtonLink
              href={`https://t.me/${telegram}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
              clazz={"button--primary"}
              square={true}
              size={"m"}
              target={"_blank"}
            >
              <Icon size={"xl"} spritePath={"telegram"} />
            </ButtonLink>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default ModelHeader;
