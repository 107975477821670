import React, {useCallback, useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask";
import {useSelector} from "react-redux";
import debounce from "lodash.debounce";
import {DayPicker} from "react-day-picker";
import {isBefore, subDays} from "date-fns";
import {cs, enUS, ru} from "date-fns/locale";

import {Icon} from "../index";
import {maskList} from "@/helper/mask_list";
import {getRegistrationStatus} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import "./inputInLabel.scss";

// Этот инпут работает в связке с React Hook Form
const InputInLabel = (props) => {
  const {
    onChange,
    id,
    title,
    clazz,
    register,
    name,
    children,
    type = "text",
    placeholder,
    size = "l-forever",
    checked,
    onClick,
    autocomplete,
    disabled,
    value,
    maskValue = "",
    setMaskValue,
    valueLoad,
    setError,
    errorType,
    required,
    price,
    notBeforeCurDate = true,
    trigger = false,
    max,
    onBlur = false,
    setInputComplete = false,
    clearErrors = false,
    inputRef = null,
    setValue = false,
    registerName = "",
    isModal = false,
    currency,
    handleCurrency = false
  } = props;

  const {lang, t} = useLadyService();
  const [phoneMaskValue, setPhoneMaskValue] = useState(maskValue);
  const phoneRef = useRef(null);

  useEffect(() => {
    if (phoneRef.current) {
      phoneMask.call(phoneRef.current);
      setTimeout(() => {
        setPhoneMaskValue(maskValue);
        phoneMask.call(phoneRef.current);
      }, 300);
    }
  }, [phoneRef, valueLoad]);

  const registrationState = useSelector(getRegistrationStatus);
  const phoneMask = function () {
    let matrix = "+###############";
    maskList.forEach((item) => {
      let code = item.code.replace(/[\s#]/g, ""),
        phone = this?.value.replace(/[\s#-)(]/g, "");

      if (phone?.includes(code)) {
        matrix = item.code;
      }
    });

    let i = 0,
      val = this?.value.replace(/\D/g, "");
    const value = matrix.replace(/(?!\+)./g, function (a) {
      return /[#\d]/.test(a) && i < val?.length
        ? val.charAt(i++)
        : i >= val?.length
          ? ""
          : a;
    });

    setPhoneMaskValue(value);
    !!setMaskValue && setMaskValue(value);

    if (setError && value !== "+" && !!value) {
      isPhoneValid(value, matrix);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const isPhoneValid = useCallback(
    debounce((value, newMatrix) => {
      const cleanedValue = value.replace(/\D/g, "");
      const digitCountInMatrix = newMatrix.replace(/[^#\d]/g, "").length;
      const actualDigitsInValue = cleanedValue.length;

      if (actualDigitsInValue !== digitCountInMatrix) {
        setError(errorType, {message: t('phoneuncorrenct')});
        if (setInputComplete) {
          setInputComplete(false)
        }
      } else {
        if (setInputComplete) {
          setInputComplete(true)
        }
        if (clearErrors) {
          clearErrors(errorType)
        }
      }
    }, isModal ? 500 : 0)
  );

  useEffect(() => {
    if (registrationState) {
      setPhoneMaskValue("");
    }
  }, [registrationState]);

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (!!phoneRef?.current) {
      const inputId = phoneRef?.current.id;
      if (inputId) {
        let inputs = document.querySelectorAll(`#${inputId}`);
        inputs.forEach((input) => {
          if (!input.value) input.value = "+";
          input.addEventListener("input", phoneMask);
          input.addEventListener("focus", phoneMask);
          input.addEventListener("blur", phoneMask);
          input.addEventListener("change", phoneMask);
        });
      }
    }
  }, [phoneRef]);

  const hours = /^([0-2])/;
  const digit = /^([0-9])/;
  const minuts = /^([0-6])/;

  const mask = [hours, digit, ":", minuts, digit];

  // DATE

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  let locale;

  switch (lang) {
    case "ru": {
      locale = ru;
      break;
    }
    case "cz": {
      locale = cs;
      break;
    }
    default: {
      locale = enUS;
      break;
    }
  }

  const childrenRef = useRef(null);

  const currentDate = new Date();

  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  const formattedDate = day + "." + month + "." + year;

  const handleDayClick = (date) => {
    if (notBeforeCurDate && isBefore(date, subDays(new Date(), 1))) {
      return;
    }

    setSelectedDate(date);
    onChange(date);
    setIsOpen((prev) => !prev);
  };

  const handleBodyClick = (event) => {
    event.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (childrenRef.current && !childrenRef.current.contains(event.target)) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  const formatHiTime = (value) => {
    let formattedValue = value;

    if (/^([2][4-9]|[3-9]\d):\d{2}$/.test(value) || value === "24:") {
      formattedValue = "23:59";
    } else if (/^\d$/.test(value)) {
      formattedValue = `0${value}:00`;
    } else if (value.endsWith(":")) {
      formattedValue = value + "00";
    } else if (/^\d{1,2}:\d$/.test(value)) {
      formattedValue = value + "0";
    }
    setValue(registerName, formattedValue)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // DATE END

  const defaultProps = {
    id: id,
    onChange: onChange,
    disabled: disabled,
    value: value,
  };

  const [valueNumber, setValueNumber] = useState(value || "");

  useEffect(() => {
    if (trigger) {
      setValueNumber(trigger);
    }
  }, [trigger]);

  const handleChange = (e) => {
    const newValue = e.target.value;

    let maxValue = newValue
    if (max) {
      maxValue = Number(newValue) > max ? max : newValue;
    }

    const finalValue = maxValue ? Math.max(0, parseInt(maxValue)) : "";
    setValueNumber(finalValue);

    !!onChange &&
    props?.onChange({target: {value: finalValue, id: props.id}});
  };

  const variants = () => {
    switch (type) {
      case "password":
        return (
          <>
            <span className={`${disabled ? "disabled" : ""}`}>{title}</span>
            <input
              {...register}
              {...defaultProps}
              placeholder={placeholder}
              type={showPass ? "text" : "password"}
              className={`input-label__password ${disabled ? "disabled" : ""}`}
              autoComplete={autocomplete}
            />
            <button
              className={`input-label__show-pass${showPass ? " _show" : ""}`}
              type={"button"}
              onClick={() => setShowPass(!showPass)}
            >
              <Icon size={"l"} spritePath={showPass ? "eye-off" : "eye"}/>
            </button>
            {children}
          </>
        );

      case "tel":
        return (
          <>
            {children}
            <InputMask
              {...register}
              {...defaultProps}
              maskPlaceholder={""}
              placeholder={"+420 000 000 000"}
              type={"tel"}
              value={phoneMaskValue}
              ref={phoneRef}
              className={`${disabled ? "disabled" : ""}`}
              autoComplete={autocomplete}
            />
          </>
        );

      case "textarea":
        return (
          <>
            {children}
            <textarea
              {...register}
              {...defaultProps}
              placeholder={placeholder}
              autoComplete={autocomplete}
            />
          </>
        );

      case "date":
        const css = `
          .my-selected:not([disabled]) { 
            background-color: #1ca182;
            color: white;
          }
        `;

        return (
          <>
            {children}

            <input
              {...register}
              {...defaultProps}
              type="text"
              value={
                selectedDate ? selectedDate.toLocaleDateString() : formattedDate
              }
              placeholder={placeholder ?? formattedDate}
              readOnly
            />

            <div
              ref={childrenRef}
              className={`input-label--date__body`}
              onClick={handleBodyClick}
            >
              <style>{css}</style>
              <DayPicker
                // mode="range"
                captionLayout="dropdown-buttons"
                fromYear={2024}
                toYear={2090}
                selected={selectedDate}
                onDayClick={handleDayClick}
                locale={locale}
                modifiersClassNames={{
                  selected: "my-selected",
                }}
                modifiers={{
                  disabled: (date) =>
                    notBeforeCurDate && isBefore(date, subDays(new Date(), 1)),
                  // today: (date) => isEqual(date, new Date()),
                }}
              />
            </div>
          </>
        );

      case "time":
        return (
          <>
            {children}
            <InputMask
              {...register}
              {...defaultProps}
              mask={mask}
              onBlur={(e) => formatHiTime(e.target.value)}
              maskPlaceholder={""}
              autoComplete={autocomplete}
              placeholder={placeholder || "18:00"}
              className={clazz}
            />
          </>
        );

      case "radio":
        return (
          <>
            <input
              {...register}
              {...defaultProps}
              type={"radio"}
              name={name}
              checked={checked}
            />
            <span className={`${disabled ? "disabled" : ""}`}>{children}</span>
          </>
        );

      case "checkbox":
        return (
          <>
            <input
              {...register}
              {...defaultProps}
              type={"checkbox"}
              name={name}
              checked={checked}
              className={"icon icon--check"}
              onClick={onClick}
            />
            {children}
          </>
        );

      case "number":
        const handleKeyDown = (e) => {
          if (
            !(
              (e.key >= '0' && e.key <= '9') ||
              e.key === 'Tab' || // Tab
              e.key === '.' || // Точка
              e.key === '-' || // Минус
              e.key === 'Backspace' || // Backspace
              e.key === 'Delete' || // Delete
              e.key === 'ArrowLeft' || // Стрелка влево
              e.key === 'ArrowRight' // Стрелка вправо
            )
          ) {
            e.preventDefault();
          }
        }
        let template = () => (
          <input
            {...register}
            {...defaultProps}
            placeholder={placeholder}
            value={valueNumber}
            type="number"
            max={max}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        );
        return (
          <>
            {children}

            {price ? (
              <div
                className={`input-price currency--${price} ${!handleCurrency && lang === 'cz' ? 'czk' : ''} ${handleCurrency && currency === "czk" ? "czk" : ""}`}>

                {template()}
                {valueNumber}
              </div>
            ) : (
              template()
            )}
          </>
        );

      default:
        return (
          <>
            {children}
            <input
              {...register}
              {...defaultProps}
              placeholder={placeholder}
              type={type}
              autoComplete={autocomplete}
              className={disabled ? "disabled" : null}
            />
          </>
        );
    }
  };

  const classVariantByType = () => {
    switch (type) {
      case "password":
        return ` input-label__password`;

      case "radio":
        return ` input-label__radio`;

      case "checkbox":
        return ` input-label__checkbox${checked ? " _checked" : ""}`;

      case "date":
        return ` input-label--date${isOpen ? " _active" : ""}`;

      case "textarea":
        return ` input-label--textarea`;

      case "number":
        return ` input-label--number${
          price ? (valueNumber === "" ? "" : " _active") : ""
        }`;

      default:
        return "";
    }
  };

  return (
    <label
      ref={inputRef}
      htmlFor={id}
      onBlur={handleBlur}
      onClick={type === "date" ? () => setIsOpen(true) : onClick}
      className={`input-label${
        clazz ? ` ${clazz}` : ""
      }${classVariantByType()}${size ? ` size-${size}` : ""}${
        required ? " required" : ""
      }${disabled ? " disabled" : ""}`}
    >
      {variants()}
    </label>
  );
};

export default InputInLabel;