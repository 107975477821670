import {
  CountriesFilter,
  CheckPhone,
  Rules,
  InfoForm,
} from "../components";

import { useForm } from "react-hook-form";

import {
  DeleteProfilePopup,
  ChangePasswordPopup,
  ActivateProfilePopup,
} from "../../../popups";

import {
  Button,
  InputInLabel,
  ButtonLink,
  Loader,
  Toggle,
} from "@/components/ui";

import { useState, useEffect, useRef } from "react";

import { makeRequest } from "@/services/makeRequest.js";
import useLadyService from "@/services/LadyService";

import showToast from "../../../toast/Toast.js";

import "./accountModel.scss";
import { setUserInfoAlreadyExist } from "@/stores/slices/userSlice.js";
import { Icon, LoadingButton } from "../../../ui/index.js";
import { capitalizeFirstLetter } from "@/helper/capitalizeFirstLetter.js";
import ActivateWa from "../../../popups/activeWa/ActiveWa.js";
import NotifyBlock from "../components/notifyBlock/NotifyBlock.js";
import useTitle from "@/hooks/useTitle.js";
import { resendEmail } from "@/helper/resendEmail.js";
import { PersonalData } from "../components/PersonalData.js";

const GoToAncketaComponent = ({ profiles_count }) => {
  const { t } = useLadyService();

  switch (!!profiles_count) {
    case true: {
      return (
        <span className={"title"}>{`${t("yourads")}: ${profiles_count}`}</span>
      );
    }

    default: {
      return <span className={"title"}>{t("addads")}</span>;
    }
  }
};

const AccountModel = () => {
  const [data, setData] = useState({});

  const {
    phone,
    balance,
    profiles_count = 0,
    email_verified_at,
    profiles_status,
    salon,
    notify_email,
    notify_telegram,
    notify_whatsapp,
  } = data;

  const { windowWidth, dispatch, lang, t, token, scrollToTop, userType } =
    useLadyService();
  const [notifyEmail, setNotifyEmail] = useState(!!notify_email);
  const [notifyTelegram, setNotifyTelegram] = useState(!!notify_telegram);
  useEffect(() => {
    if (notify_email) {
      setNotifyEmail(!!notify_email);
    }
  }, [notify_email]);
  useEffect(() => {
    if (notify_telegram) {
      setNotifyTelegram(!!notify_telegram);
    }
  }, [notify_telegram]);

  const [isLoading, setIsLoading] = useState(true);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [valueLoad, setValueLoad] = useState(false);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);

  const [status, setStatus] = useState(profiles_status);
  const [isBrothel, setIsBrothel] = useState(salon);

  const isSmallTablet = windowWidth < 1000.98;
  const isMobile = windowWidth < 767.98;
  const isMobileExtra = windowWidth < 420;

  const goToAncketa = isSmallTablet ? t("toads") : t("browseprofiles");

  const goToBank = isSmallTablet ? t("tobalance") : t("gotobalance");

  const isActiveProfile = isMobileExtra ? t("activate") : t("activatepro");
  const { register, setValue } = useForm();

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };

  const handleOpenPassword = () => {
    setIsOpenPassword(true);
  };

  const handleOpenActivate = () => {
    setIsOpenActivate(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setValueLoad(false);
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          const result = data.data;

          setStatus(result.profiles_status);

          setIsBrothel(result.salon);

          setData(result);
          dispatch(setUserInfoAlreadyExist());
        }

        setIsLoading(false);
        setValueLoad(true);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `services/all`;
        const method = "GET";
        const payload = { lang };

        const { cities } = await makeRequest({ route, method, payload });
        setCities(cities);
      } catch (error) {}
    };

    token && lang && fetchData();
  }, [token, lang]);

  const handleActivate = async () => {
    try {
      const route = `user/set-profiles-status`;
      const method = "PUT";

      const payload = {
        status: !status,
      };
      setStatus((prev) => !prev);
      setIsOpenDelete(false);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setStatus((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const handleActivateBrothel = async () => {
    try {
      const route = `user/set-salon-status`;
      const method = "PUT";
      const payload = {
        status: !isBrothel,
      };
      setIsBrothel((prev) => !prev);

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setIsBrothel((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const [activeWaOpen, setActiveWaOpen] = useState(false);

  useEffect(() => {
    setValueLoad(false);
    setValue("phone", String(phone));
    setValueLoad(true);
  }, [setValue, phone]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useTitle(t("profuser") + " - Lady4Love");

  return (
    <main>
      <section className={"container"}>
        <h1>{t("profuser")}</h1>
        <div className={"account-page__root"}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={"account-page__main"}>
              <PersonalData phone={phone} valueLoad={valueLoad} setIsOpenPassword={setIsOpenPassword}/>

              <hr />

              {!isLoading && isSmallTablet && (
                <>
                  <Rules />
                  <hr />
                </>
              )}
              <NotifyBlock
                email_verified_at={email_verified_at}
                setActiveWaOpen={setActiveWaOpen}
                notify_email={notifyEmail}
                notify_telegram={notifyTelegram}
                notify_whatsapp={notify_whatsapp}
                setNotifyEmail={setNotifyEmail}
                setNotifyTelegram={setNotifyTelegram}
              />

              {!email_verified_at && (
                <div className="account-page__confirm">
                  <span className="p2">{t("toconfemail")}</span>
                  <LoadingButton
                    size={"s"}
                    square={false}
                    isLoading={isResendLoading}
                    clazz="button_outline--green-accent wd-160"
                    onClick={async () => {
                      setIsResendLoading(true)
                      const result = await resendEmail();
                      if (result.success) {
                        showToast({
                          message: t("sent"),
                          variant: "success",
                        });
                      }
                      if (!result) {
                        showToast({
                          message: t("tomanyrequests"),
                          variant: "error",
                        });
                      }
                      setIsResendLoading(false)
                    }}
                  >
                    {t("reconf")}
                  </LoadingButton>
                </div>
              )}

              <InfoForm data={data} cities={cities} type={"indi"} />
              <div className="account-page__saloon">
                <div className="account-page__removebtn__active">
                  <h3>{t("brothel")}</h3>
                  <Toggle
                    id={"isActiveSalon"}
                    checked={!!isBrothel}
                    onClick={handleActivateBrothel}
                  />
                </div>
                <span>{t("brocheck")}</span>
              </div>
              <div className={"account-page__info"}>
                <div className={"account-page__link-block"}>
                  <GoToAncketaComponent profiles_count={profiles_count} />
                  <ButtonLink
                    href={"profiles"}
                    clazz={"td-none button_outline--green-accent wd-160"}
                    size={"s"}
                  >
                    {goToAncketa}
                  </ButtonLink>
                </div>

                <div className={"account-page__link-block"}>
                  <span className={"title"}>
                    {t("yourbalance")}:
                    <span className={"account-page__cash"}>
                      {` ${Math.round(balance)}`}
                      <Icon
                        title={t("EroCoins")}
                        spritePath={"erocoin"}
                        size={"s"}
                        clazz={"ml-4"}
                      />
                    </span>
                  </span>
                  <ButtonLink
                    href={"/lk/balance"}
                    clazz={"td-none button_outline--green-accent wd-160"}
                    size={"s"}
                  >
                    {goToBank}
                  </ButtonLink>
                </div>
              </div>

              <hr />

              <div className="account-page__info">
                <h2>{t("usefulfeatures")}</h2>
                <CheckPhone />
                <CountriesFilter data={data} />
              </div>
              <div className="account-page__removebtn">
                <div className="account-page__removebtn__active">
                  <h3>{isActiveProfile}</h3>
                  <Toggle
                    id={"isActiveProfile"}
                    checked={!!status}
                    onClick={status ? handleOpenActivate : handleActivate}
                  />
                </div>

                <Button
                  type="button"
                  clazz={"button--error"}
                  size={"l"}
                  onClick={handleOpenDelete}
                >
                  {capitalizeFirstLetter(t("deleteprofile"))}
                </Button>
              </div>
            </div>
          )}
          {!isLoading && !isSmallTablet && <Rules />}
        </div>
      </section>

      {isOpenDelete && (
        <DeleteProfilePopup
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          isActiveProfile={isActiveProfile}
          status={status}
          handleActivate={handleActivate}
        />
      )}

      {isOpenPassword && (
        <ChangePasswordPopup
          open={isOpenPassword}
          setOpen={setIsOpenPassword}
        />
      )}

      {isOpenActivate && (
        <ActivateProfilePopup
          open={isOpenActivate}
          setOpen={setIsOpenActivate}
          setStatus={setStatus}
          status={status}
        />
      )}

      {activeWaOpen && (
        <ActivateWa
          open={activeWaOpen}
          setOpen={setActiveWaOpen}
        />
      )}
    </main>
  );
};

export default AccountModel;
