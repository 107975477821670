import {
  BurgerMenu,
  DropdownLanguage, HeaderTabs,
  InstructionLink,
  Logo,
  MenuNavBar,
  SelectCity,
  SupportButton,
  UserWrapperDesktop
} from "../components";
import Filters from "../components/Filters";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import useLadyService from "@/services/LadyService";
import {Burger, ButtonLink, Icon, SwitchTheme} from "@/components/ui";
import {useSelector} from "react-redux";
import {getScrolledClass} from "@/stores/slices/headerSlice";
import TogglesOrLinks from "../components/TogglesOrLinks";

const ModelOrAgency = () => {
  const { windowWidth, t, userType, isLk } = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <ButtonLink
            href={"/lk/photograph"}
            title={t("photographer")}
            size={"xs"}
            clazz={"add-form button_outline--green _no-underline"}
          >
            <Icon size={"m"} spritePath={"camera"} />
            {t("photographer")}
          </ButtonLink>

          <InstructionLink/>

          <TogglesOrLinks/>

          <SupportButton square={false} size={"xs"} iconSize={"m"} />

          <DropdownLanguage />

          <SwitchTheme type={"switch"} />
        </div>

        <div className="header__bottom">
          <Logo scrolledClass={scrolledClass}/>

          {isLk ? (
            <div className={"header__tabs"}>
              <HeaderTabs/>
            </div>
          ) : (
            <>
              <SelectCity />

              <Filters/>
            </>
          )}

          <UserWrapperDesktop/>
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <>
        <div className="header__top">
          <Logo scrolledClass={scrolledClass}/>
          {!isLk ? (
            <>
              <SelectCity />

              <Filters/>
            </>
          ) : null}

          <div className="user__wrapper">
            {windowWidth > 1000.98 ? <TogglesOrLinks/> : null}

            <SupportButton square={true} size={"s"} iconSize={"m"} />

            <DropdownLanguage size={"s"} />

            <Burger />

            <SwitchTheme type={"switch"} />

            <BurgerMenu lk={isLk}/>
          </div>

          {!isLk && <ShortFilter />}
        </div>

        {windowWidth < 1000.98 && (
          <div className="header__bottom"><TogglesOrLinks/></div>
        )}
      </>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Burger />
          <DropdownLanguage />
        </div>

        <Logo scrolledClass={scrolledClass}/>

        <div className="user__wrapper">
          <SupportButton square={true} />

          <SwitchTheme type={"switch"} />
        </div>

        <BurgerMenu lk={isLk}/>

        <MenuNavBar />

        {!isLk && <ShortFilter />}
      </div>

      <div className="header__bottom"><TogglesOrLinks/></div>
    </>
  );
};

export default ModelOrAgency