import {Button, Icon, Toggle} from "../../../../../ui";
import useLadyService from "@/services/LadyService";
import BlockWrapper from "../../../components/block/BlockWrapper";
import {EditOperatorPopup} from "../../../../../popups";
import {setEditOperatorStatus} from "../../../../../../stores/slices/popupSlice";

const OperatorsPage = () => {
    const {t, dispatch} = useLadyService()


    const openEditOperator = () => {
      dispatch(setEditOperatorStatus(true))
    }

    return(
      <main className={'profiles-page__container w-100'}>
        <div className="profiles-page__header">
          <h1>{t("myoperator")}</h1>
          <Button
            size={'l-forever'}
            clazz={'button--green'}
            onClick={openEditOperator}
          >
            <Icon
              size={"l"}
              spritePath={"plus-1"}
            />
            {t('addad')}
          </Button>
        </div>

        <div className="d-flex fd-column gap-12">
            <BlockWrapper clazz={'block-wrapper--operator'}>
                <div className="block-wrapper--operator__name">
                  <Toggle words={false}/>

                  <div className="d-flex fd-column gap-8">
                    <b className={'color-500 p3'}>{t('names')}</b>
                    <span className={'p1 color-main'}>Valentin</span>
                  </div>
                </div>

                <div className="block-wrapper--operator__wrapper">
                  <div className="d-flex fd-column gap-8">
                    <b className={'color-500 p3'}>Email (login)</b>
                    <span className={'p1 color-main'}>valentin-mang@gmail.com</span>
                  </div>

                  <div className={'d-flex gap-8 justify-end'}>
                    <Button
                      size={'m'}
                      clazz={'button--secondary'}
                      onClick={openEditOperator}
                    >
                      {t('edit')}
                    </Button>

                    <Button
                      size={'m'}
                      clazz={'button--error'}
                    >
                      {t('delete')}
                    </Button>
                  </div>
                </div>
            </BlockWrapper>
        </div>

        <EditOperatorPopup

        />
      </main>
    )
}

export default OperatorsPage