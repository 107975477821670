import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useLadyService from "../../../../services/LadyService";
import { makeRequest } from "../../../../services/makeRequest";
import { setUserInfoAlreadyExist } from "../../../../stores/slices/userSlice";
import {
  ActivateProfilePopup,
  ChangePasswordPopup,
  DeleteProfilePopup,
} from "../../../popups";
import showToast from "../../../toast/Toast";
import {
  InputInLabel,
  Loader,
  Button,
  ButtonLink,
  Icon,
  LoadingButton,
} from "../../../ui";
import TariffsTable from "../../../tariffsTable/TariffsTable";
import {InfoForm, Rules} from "../components";
import { useLocation } from "react-router-dom";
import NotifyBlock from "../components/notifyBlock/NotifyBlock";
import useTitle from "@/hooks/useTitle";
import { resendEmail } from "@/helper/resendEmail";
import { PersonalData } from "../components/PersonalData";

const GoToComponent = ({ count, type }) => {
  const { t } = useLadyService();

  const text =
    type === "history"
      ? t("history") + " " + t("profiles")
      : t("subandfilters");

  return <span className={"title"}>{`${text}: ${count}`}</span>;
};

const CustomerPage = () => {
  const [data, setData] = useState({});

  const { hash } = useLocation();

  const {
    phone,
    balance,
    email_verified_at,
    profiles_status,
    name,
    notify_email,
    notify_telegram,
    notify_whatsapp,
  } = data;
  const [notifyEmail, setNotifyEmail] = useState(!!notify_email);
  const [notifyTelegram, setNotifyTelegram] = useState(!!notify_telegram);

  useEffect(() => {
    if (notify_email) {
      setNotifyEmail(!!notify_email);
    }
  }, [notify_email]);

  useEffect(() => {
    if (notify_telegram) {
      setNotifyTelegram(!!notify_telegram);
    }
  }, [notify_telegram]);

  const { windowWidth, dispatch, t, token, lang, scrollToTop, scrollToHash } =
    useLadyService();
  const [isLoading, setIsLoading] = useState(true);

  const [valueLoad, setValueLoad] = useState(false);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);

  const [status, setStatus] = useState(profiles_status);

  const isSmallTablet = windowWidth < 1000.98;
  const isMobile = windowWidth < 767.98;
  const isMobileExtra = windowWidth < 420;

  const isActiveProfile = isMobileExtra ? t("activate") : t("activatepro");
  const tariffsRef = useRef(null);

  const { register, setValue } = useForm();

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };

  const handleOpenPassword = () => {
    setIsOpenPassword(true);
  };

  const handleOpenActivate = () => {
    setIsOpenActivate(true);
  };

  const [cities, setCities] = useState([]);
  const [filtersCount, setFiltersCount] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);
  const [isResendLoading, setIsResendLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `services/all`;
        const method = "GET";
        const payload = { lang };

        const { cities } = await makeRequest({ route, method, payload });
        setCities(cities);
      } catch (error) {}
    };

    token && lang && fetchData();
  }, [token, lang]);

  useEffect(() => {
    const fetchData = async () => {
      setValueLoad(false);
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        if (data) {
          const result = data.data;

          setStatus(result.profiles_status);
          setFiltersCount(result.filters_count);
          setHistoryCount(result.actions_count);
          setData(result);
          dispatch(setUserInfoAlreadyExist());
        }

        setIsLoading(false);
        setValueLoad(true);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  useEffect(() => {
    scrollToHash();
  }, [isLoading]);

  const handleActivate = async () => {
    try {
      const route = `user/set-profiles-status`;
      const method = "PUT";

      const payload = {
        status: !status,
      };
      setStatus((prev) => !prev);
      setIsOpenDelete(false);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setStatus((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setValueLoad(false);
    setValue("phone", String(phone));
    setValueLoad(true);
  }, [setValue, phone]);

  useEffect(() => {
    if (!hash) {
      scrollToTop();
    }
  }, []);

  useTitle(t("profuser") + " - Lady4Love");

  return (
    <main className={"container"}>
        <h1>{t("profuser")}</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={"account-page__root"}>
              <div className={"account-page__main"}>
                <PersonalData phone={phone} valueLoad={valueLoad} setIsOpenPassword={setIsOpenPassword}/>

                <hr/>

                {!isLoading && isSmallTablet && (
                  <>
                    <Rules/>
                    <hr/>
                  </>
                )}
                <NotifyBlock
                  email_verified_at={email_verified_at}
                  setNotifyEmail={setNotifyEmail}
                  setNotifyTelegram={setNotifyTelegram}
                  tariffsRef={tariffsRef}
                  notify_email={notifyEmail}
                  notify_telegram={notifyTelegram}
                  notify_whatsapp={notify_whatsapp}
                />

                {!email_verified_at && (
                  <div className="account-page__confirm">
                    <span className="p2 color-main">{t("toconfemail")}</span>
                    <LoadingButton
                      size={"s"}
                      square={false}
                      isLoading={isResendLoading}
                      clazz="button_outline--green-accent wd-160"
                      onClick={async () => {
                        setIsResendLoading(true);
                        const result = await resendEmail();
                        if (result.success) {
                          showToast({
                            message: t("sent"),
                            variant: "success",
                          });
                        }
                        if (!result) {
                          showToast({
                            message: t("tomanyrequests"),
                            variant: "error",
                          });
                        }
                        setIsResendLoading(false);
                      }}
                    >
                      {t("reconf")}
                    </LoadingButton>
                  </div>
                )}

                <InfoForm data={data} cities={cities} type={"customer"}/>

                {/* <span>{t("addresshere")}</span> */}

                <hr/>

                <div className={"account-page__info"}>
                  <div className={"account-page__link-block"}>
                    <GoToComponent count={filtersCount}/>
                    {!!filtersCount && (
                      <ButtonLink
                        href={"subscriptions"}
                        clazz={"td-none button_outline--green-accent wd-160"}
                        size={"s"}
                      >
                        {t("goto")}
                      </ButtonLink>
                    )}
                  </div>
                  <div className={"account-page__link-block"}>
                    <GoToComponent count={historyCount} type="history"/>

                    {!!historyCount && (
                      <ButtonLink
                        href={"/history"}
                        clazz={"td-none button_outline--green-accent wd-160"}
                        size={"s"}
                      >
                        {t("goto")}
                      </ButtonLink>
                    )}
                    <span className="fz-15">{t("adsstore")}</span>
                  </div>

                  <div className={"account-page__link-block"}>
                    <span className={"title"}>
                      {t("yourbalance")}:
                      <span className={"account-page__cash"}>
                        {` ${Math.round(balance)}`}
                        <Icon
                          title={t("EroCoins")}
                          spritePath={"erocoin"}
                          size={"s"}
                          clazz={"ml-4"}
                        />
                      </span>
                    </span>
                    <ButtonLink
                      href={"/lk/balance"}
                      clazz={"td-none button_outline--green-accent wd-160"}
                      size={"s"}
                    >
                      {t("goto")}
                    </ButtonLink>
                  </div>
                </div>

                <hr/>

                <div className="account-page__removebtn">
                  {/* <div className="account-page__removebtn__active">
                    <h3>{isActiveProfile}</h3>
                    <Toggle
                      id={"isActiveProfile"}
                      checked={!!status}
                      onClick={status ? handleOpenActivate : handleActivate}
                    />
                  </div> */}

                  <Button
                    type="button"
                    clazz={"button--error ml-auto"}
                    size={"l"}
                    onClick={handleOpenDelete}
                  >
                    {t("deleteprofile")}
                  </Button>
                </div>
              </div>

              {!isLoading && !isSmallTablet && <Rules/>}
            </div>

            <section id={'tariffs'}>
              <h1 className={"mb-40 mb-24-mob"}>{t("selecttariff")}</h1>

              <TariffsTable refElem={tariffsRef}/>
            </section>
          </>
        )}

        {isOpenDelete && (
          <DeleteProfilePopup
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            isActiveProfile={isActiveProfile}
            status={status}
            handleActivate={handleActivate}
          />
        )}

        {isOpenPassword && (
          <ChangePasswordPopup
            open={isOpenPassword}
            setOpen={setIsOpenPassword}
          />
        )}

        {isOpenActivate && (
          <ActivateProfilePopup
            open={isOpenActivate}
            setOpen={setIsOpenActivate}
            setStatus={setStatus}
            status={status}
          />
        )}
    </main>
  );
};

export default CustomerPage;
