import { useEffect, useRef, useState } from "react";
import { Icon } from "@/components/ui";
import InputZone from "./InputZone";
import { InputInLabel } from "../../../../ui";
import useLadyService from "@/services/LadyService";

export const MediaZone = ({
  h3,
  title,
  subtitle,
  isMulti = false,
  isVideo = false,
  setVideoPresentationValue = false,
  videoPresentationValue = false,
  isH3 = true,
  isBigPhoto = false,
  w_100 = false,
  interior = false,
}) => {
  const { t } = useLadyService();
  const [media, setMedia] = useState([]);
  const videoRef = useRef(null);
  const handleRemoveMedia = (index) => {
    setMedia((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };

  const repeatInputRef = useRef(null);

  useEffect(() => {
    if (videoPresentationValue && repeatInputRef.current) {
      repeatInputRef.current.focus();
    }
  }, [repeatInputRef, videoPresentationValue]);

  const accept = isVideo
    ? {
        "video/mp4": [".mp4", ".MP4"],
        "video/quicktime": [".mov", ".MOV"],
        "video/x-ms-wmv": [".wmv", ".WMV"],
        "video/x-msvideo": [".avi", ".AVI"],
        "video/x-matroska": [".mkv", ".MKV"],
      }
    : {
        "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
        "image/png": [".png", ".PNG"],
        "image/webp": [".webp", ".WEBP"],
      };

  const Wrapper = ({ children }) => {
    if (isH3) {
      return (
        <div className="d-flex fd-column w-100">
          {h3 && <h3>{h3}</h3>}
          {children}
        </div>
      );
    } else {
      return children;
    }
  };

  return (
    <Wrapper>
      {!!media.length && !isMulti && (
        <div
          className={`example__photo ${w_100 ? "w-100" : ""} ${
            isBigPhoto ? "example__photo-big" : ""
          } ${interior ? "example__photo-interior" : ""} ml-auto mr-auto`}
        >
          {media[0].type.includes("video") ? (
            <video
              width={`${isBigPhoto ? "100%" : "340px"}`}
              height="208px"
              controls
              ref={videoRef}
              src={media[0].preview}
            />
          ) : (
            <img src={media[0].preview} alt="" />
          )}
          <span className="icon-remove" onClick={() => handleRemoveMedia(0)}>
            <Icon size="s" spritePath="close" />
          </span>
        </div>
      )}
      {isMulti && (
        <div className="agency-interior__images">
          {media.map((item, index) => (
            <div
              key={index}
              className={`example__photo ${
                interior ? "example__photo-interior" : ""
              }`}
            >
              {item.type.includes("video") ? (
                <video
                  width={`${isBigPhoto ? "100%" : "340px"}`}
                  height="208px"
                  controls
                  ref={videoRef}
                  src={item.preview}
                />
              ) : (
                <img src={item.preview} alt="" />
              )}
              <span
                className="icon-remove"
                onClick={() => handleRemoveMedia(index)}
              >
                <Icon size="s" spritePath="close" />
              </span>
            </div>
          ))}

          <InputZone
            isMulti={isMulti}
            setVideoPresentationValue={setVideoPresentationValue}
            videoPresentationValue={videoPresentationValue}
            isVideo={isVideo}
            title={title}
            subtitle={subtitle}
            data={media}
            setData={setMedia}
            accept={accept}
          />
        </div>
      )}

      {!media.length && !isMulti && !videoPresentationValue && (
        <InputZone
          isMulti={isMulti}
          setVideoPresentationValue={setVideoPresentationValue}
          videoPresentationValue={videoPresentationValue}
          isVideo={isVideo}
          title={title}
          subtitle={subtitle}
          data={media}
          setData={setMedia}
          accept={accept}
        />
      )}
      {videoPresentationValue && (
        <div className="eroLink">
          <InputInLabel
            inputRef={repeatInputRef}
            type={"text"}
            id={"upload-agency-video-repeat"}
            clazz={"mb-12 align-start w-50"}
            value={videoPresentationValue}
            placeholder={"https://vimeo.com/93721124"}
            onChange={(e) => setVideoPresentationValue(e.target.value)}
          >
            {t("linktovideo")}
          </InputInLabel>

          <div className={`agency-card__img img-wrapper`}></div>

          <span className="ero-status color-main text-center">
            {t("processing")}
          </span>
        </div>
      )}
    </Wrapper>
  );
};
