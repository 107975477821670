import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSearchValue, setSearchValue } from "@/stores/slices/modelsState";
import { Button, Icon } from "../index";

import "./input.scss";
import { hideShort } from "@/stores/slices/showFilterSlice";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import { cleanSorted, setSortedModelsByFilter } from "../../../stores/slices/modelsState";
import { resetAll } from "../../../stores/slices/filterSlice";

const InputNick = (props) => {
  const {
    type,
    placeholder,
    id,
    clazz,
    autocomplete = "off",
    name,
    scrolledClass = "",
  } = props;

  const { windowWidth, dispatch, scrollToTop } = useLadyService();


  const { t } = useTranslation("translation");

  const searchValue = useSelector(getSearchValue);

  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if(!searchValue) {
      setValue("")
    }
  }, [searchValue])

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };
  const handleClick = () => {
    if (scrolledClass) {
      scrollToTop(100);
    }
    dispatch(setSearchValue(value));
    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(setSortedModelsByFilter(""));

    if (windowWidth < 767.98) {
      dispatch(hideShort());
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (scrolledClass) {
        scrollToTop(100);
      }
      if (windowWidth < 767.98) {
        dispatch(hideShort());
      }
      dispatch(cleanSorted());
      dispatch(resetAll());
      dispatch(setSortedModelsByFilter(""));
      
      dispatch(setSearchValue(value));
    }
    if (event.key === "Escape") {
      setValue("");
      dispatch(setSearchValue(""));
    }
  };

  useEffect(() => {
    return () => {
      setValue("");
    };
  }, []);

  return (
    <div className="search">
      <label htmlFor={id} className="search__label header__search--label">
        <input
          autoComplete={autocomplete}
          className={`search__input ${clazz ? clazz : ""}`}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          id={id}
        />

        <Button
          square={true}
          size={"xs"}
          clazz="search__icon"
          onClick={() => handleClick()}
        >
          <Icon size={"m"} spritePath={"search"} />
        </Button>
      </label>
      {value && (
        <Button
          title={t("reset")}
          onClick={() => {
            setValue("");
            dispatch(setSearchValue(""));
          }}
          clazz="search__close"
        >
          <Icon size={"m"} spritePath={"close"} />
        </Button>
      )}
      <Button
        square={true}

        size={"xs"}
        clazz="search__icon"
        onClick={() => handleClick()}
      >
        <Icon size={"m"} spritePath={"search"} />
      </Button>
    </div>
  );
};

export default InputNick;
