import { toast } from "react-toastify";
import { ButtonLink, Icon } from "@/components/ui";
import "./toast.scss";
import { t } from "i18next";

const showToast = ({ message, subMessage, href, linkTitle, type = "default", variant, userType }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const urlParams = window?.location?.pathname;
  const isVideo = urlParams.includes("lk/indi/videochats");
  const isRes = urlParams.includes("lk/indi/reservations");

  const toastType = {
    video: {
      message:
        t("newreq") +
        " " +
        t("onthe").toLowerCase() +
        " " +
        t("videochat").toLowerCase(),

      subMessage: capitalizeFirstLetter(t("foronemin")) + " " + t("youwillget"),
      link: {
        href: `/lk/indi/videochats`,
        buttonTitle: t("goto"),
      },
      timeMessage: {
        tilte: t("timeroaccept"),
        time: 2 + " " + t("min"),
      },
    },

    booking: {
      message:
        t("newreq") +
        " " +
        t("onthe").toLowerCase() +
        " " +
        t("onlineorder").toLowerCase(),

      subMessage:
        t('afterapprove'),
      link: {
        href: `/lk/indi/reservations`,
        buttonTitle: t("goto"),
      },
    },

    erocontentAccess: {
      message: `${t("insuff")} ${t("thistran")}`,
      link: {
        href: `/lk/topup`,
        buttonTitle: t("balancetopup"),
      },
    },

    insuff: {
      message: `${t("insuff")}`,
      link: {
        href: `/lk/topup`,
        buttonTitle: t("balancetopup"),
      },
    },
    transaction_credit: {
      message: `${t("topausuccess")}`,
      link: {
        href: `/lk/${userType}/balance`,
        buttonTitle: t("balancetopup"),
      },
    },
    transaction_debit: {
      message: `${t("drawcomplete")}`,
      link: {
        href: `/lk/topup`,
        buttonTitle: t("balancetopup"),
      },
    },

    default: {
      message,
      subMessage,
      link: {
        href: href,
        buttonTitle: t(`${linkTitle}`),
      },
    },
  };

  let variantStatus = "success";
  let iconColor = "green";
  let spritePath = "video";
  let size = "m";
  switch (variant) {
    case "info": {
      variantStatus = "info";
      iconColor = "";
      spritePath = "info";
      size = "l";
      break;
    }

    case "error": {
      variantStatus = "error";
      iconColor = "red";
      spritePath = "error";
      size = "l";
      break;
    }

    case "success": {
      variantStatus = "success";
      iconColor = "green";
      spritePath = "check";
      size = "l";
      break;
    }

    case "order": {
      variantStatus = "success";
      spritePath = "pay-content";
      size = "l";
      break;
    }

    case "booking": {
      variantStatus = "info";
      iconColor = "green";
      spritePath = "pay-content";
      size = "m";
      break;
    }

    default: {
      variantStatus = "info";
      iconColor = "green";
      spritePath = "video";
      size = "m";
      break;
    }
  }

  

  const CustomToast = ({ toastId }) => {
    return (
      <div className={`toast${!!variantStatus ? ` ${variantStatus}` : ""}`}>
        <Icon
          size={size}
          spritePath={spritePath}
          clazz={iconColor ? `_${iconColor}` : ""}
        />
        <div className={`toast__root`}>
          <h3>
            {toastType[type]["message"]}
          </h3>
          {subMessage && <p className="p1">{subMessage}</p>}
          {type !== "default" && (
            <>
              {type === "video" ? (
                <span className="p1">
                  {toastType[type]["subMessage"]}
                  <div className="toast__youget p1">
                    2
                    <Icon
                      title={t("EroCoins")}
                      clazz={`color-main ml-4`}
                      spritePath={"erocoin"}
                      size={"xs"}
                    />
                  </div>
                </span>
              ) : (
                <span className="p2">{toastType[type]["subMessage"]}</span>
              )}
              {type === "video" && (
                <p className="toast__info p3">
                  {toastType[type]["timeMessage"]["tilte"]}
                  <span className="toast__try">
                    {toastType[type]["timeMessage"]["time"]}
                  </span>
                </p>
              )}
            </>
          )}
          {(type === "video" && !isVideo) || (type === "booking" && !isRes) || type === "erocontentAccess" || type === "insuff" ? (
            <ButtonLink
              clazz={"button--green w-100 _no-underline"}
              size={"s"}
              href={toastType[type]["link"]["href"]}
              tilte={toastType[type]["link"]["buttonTitle"]}
              onClick={() => toast.dismiss(toastId)}
            >
              {type === "booking" ? t("g-preorders") : type === "video" ? t('videochats') : type === "insuff" ? t('toearn') : t("goto")}
            </ButtonLink>
          ) : null}
        </div>
      </div>
    );
  };

  const options = {
    position: "top-right",
    autoClos: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    progress: undefined,
    className: "toast",
  };

  toast(<CustomToast />, options);
};

export default showToast;