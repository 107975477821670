import useLadyService from "@/services/LadyService";

const TopLinksTabsObj = () => {
  const {t, userBalance, lang} = useLadyService();

  let wallet = {id: 10, name: t("balance"), href: `${lang}/lk/balance`, wallet: userBalance}

  return {
    default: [
      {id: 1, name: t("about"), href: `/about`, count: 0},
      {id: 2, name: t("guide"), href: `/guide`, count: 0},
      {id: 3, name: t("support"), href: `/lk/support`, count: 0},
    ],
    photograph: [
      {id: 1, name: t("profuser"), href: "#", count: 0},
      {id: 2, name: t("photo"), href: "#", count: 0},
      {id: 3, name: t("videofotograph"), href: "#", count: 0},
      {id: 4, name: t("interior"), href: "#", count: 0},
      {id: 5, name: t("reviews"), href: "#", count: 0},
    ],
    customer: [
      {id: 1, name: t("profuser"), href: `/lk/customer`},
      {
        id: 2,
        name: t("subandfilters"),
        href: `/lk/customer/subscriptions`,
        count: 0,
      },
      {id: 3, name: t("history"), href: `/history`, count: 0},
      wallet,
    ],
    agency: [
      {id: 1, name: t("profuser"), href: `/lk/agency`},
      {id: 2, name: t("myads"), href: `/lk/agency/profiles`, count: 0},
      {id: 3, name: t("reviews"), href: `#`, count: 0},
      {id: 4, name: t("myoperator"), href: `${lang}/lk/agency/operators`},
      {id: 5, name: t("myparty"), href: `#`},
      {id: 6, name: t("mystat"), href: `#`},
      wallet,
    ],
    indi: [
      {id: 1, name: t("profuser"), href: "/lk/indi"},
      {id: 2, name: t("myads"), href: "/lk/indi/profiles", count: 0},
      {id: 3, name: t("reviews"), href: "/lk/indi/reviews", count: 0},
      {id: 4, name: t("mystat"), href: "/lk/indi/statistic"},
      wallet,
    ],
    indi_lk: [
      {id: 1, name: t("profuser"), href: "/lk/indi"},
      {id: 2, name: t("myads"), href: "/lk/indi/profiles"},
      {id: 3, name: t("reviews"), href: "/lk/indi/reviews"},
      {id: 4, name: t("mystat"), href: "/lk/indi/statistic"},
      wallet,
    ],
    admin_lk: [
      {id: 1, name: t("profuser"), href: "/lk/indi"},
      {id: 2, name: t("myads"), href: "/lk/indi/profiles"},
      wallet,
    ],
  }
};

export default TopLinksTabsObj