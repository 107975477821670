import {
  Button,
  ButtonLink,
  CheckboxNavigation,
  Dropdown,
  Icon,
  Skeleton,
  Select,
} from "@/components/ui";
import React, { useEffect, useState } from "react";
import GirlCard from "../cards/girlCard/GirlCard";
import axios from "axios";
import StoriesSlider from "../sliders/storiesSlider/StoriesSlider";
import { useSelector } from "react-redux";
import {
  showFilter,
  showShort,
  showShortFilter,
} from "@/stores/slices/showFilterSlice";
import {
  filterAllModels,
  getAllModels,
  getGenderSorted,
  getHasMorePage,
  getSearchValue,
  getSortedModelsByFilter,
  getStatusSorted,
  setNotDisabledCheckbox,
  setPage,
  getPage,
  getTypeSorted,
  setAllModels,
  setHasMorePage,
  updateAllModels,
  cleanSorted,
  getAgeForModels,
  getAllSorted,
  getIsLoading,
  getIsMainPage,
  getIsParamsExist,
  getPriceForModels,
  setArrSorted,
  setIsLoading,
  setIsMainPage,
  setIsParamsExist,
} from "@/stores/slices/modelsState";
import { getTitle, setDefaultTitle } from "@/stores/slices/titleSlice";
import {
  extractKeysAndNumbers,
  filterGender,
  filterPlace,
  filterStatus,
  isFilterSearch,
} from "@/stores/slices/filterSlice";
import Notifications from "../notifications/Notifications";
import {
  getResultFilterString,
  getGenderCount,
  resetStateCount
} from "@/stores/slices/counterFilterSlice";
import { getProfilesCount } from "@/stores/slices/mapModelSlice";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { getUserCurrentCity, getCountriesData} from "@/stores/slices/userSlice";
import baseUrl from "@/services/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import {
  setDefaultRange,
  setIsRangeLoad,
  toggleSelectedArr,
  toggleStateArr,
  rangeChange,
  resetAccordionByStatus,
  resetSelected,
  resetState,
  resetStateAcc,
  resetToggleAcc,
} from "@/stores/slices/filterSlice";
import { SelectCountry } from "../ui";
import ShortFilterData from "../shortFilter/ShortFilterData";
import { setFilterMiniPopupStatus } from "../../stores/slices/filterPopupSlice";

import "./navigation.scss";
import { getProfilesFilterCount } from "../../stores/slices/mapModelSlice";
import GetProfilesUrl from "@/services/GetProfiles";
const Navigation = ({ isDataEmpty, setIsDataEmpty }) => {
  const { dispatch, lang, windowWidth, scrollToTop, city, t, token } =
    useLadyService();

  const filterNavigation = [
    { id: 1, link: "#", title: t("bynew"), value: "date" },
    { id: 2, link: "#", title: t("byprice"), value: "price" },
    { id: 3, link: "#", title: t("byrating"), value: "rating" },
  ];

  useEffect(() => {
    dispatch(setIsMainPage(true));

    return () => {
      dispatch(setIsMainPage(false));
      dispatch(setFilterMiniPopupStatus(false));
      dispatch(setFilterMiniPopupStatus(false));
    };
  }, []);

  const currentCity = useSelector(getUserCurrentCity);

  const morePage = useSelector(getHasMorePage);
  const models = useSelector(getAllModels);
  const [searchCount, setSearchCount] = useState("");

  const allowedWords = [
    "indi",
    "elitelady",
    "pornstar",
    "online",
    "new",
    "vchat",
    "booking",
    "ero",
    "verified",
    "healthy",
    "couple",
    "bdsm",
    "trance",
    "guys",
    "incall",
  ];

  const tagsForStatus = [
    "indi",
    "elitelady",
    "pornstar",
    "online",
    "new",
    "vchat",
    "booking",
    "ero",
    "verified",
    "healthy",
    "bdsm",
    "video",
    "escort",
    "travel",
    "gf",
    "incall",
  ];

  const tagsForGender = [
    "about",
    "couple",
    "trance",
    "male",
    "guys",
    "trans",
    "female",
  ];

  const { params: thirdSegment } = useParams();
  const paramsExist = useSelector(getIsParamsExist);

  useEffect(() => {
    if (thirdSegment && lang && city && !paramsExist) {
      const arrParams = thirdSegment.split("-");

      const hasInvalidWord = arrParams.some(
        (word) => !allowedWords.includes(word)
      );
      if (hasInvalidWord) {
        navigate("/404");
        return;
      }
      if (thirdSegment) {
        dispatch(setDefaultTitle(thirdSegment.split("-")));
      }
      const result = arrParams.filter((tag) => allowedWords.includes(tag));
      dispatch(setArrSorted(result));
      const validStatus = arrParams.filter((tag) =>
        tagsForStatus.includes(tag)
      );
      if (!!validStatus.length) {
        dispatch(toggleStateArr({ StateName: "status", value: validStatus }));
        dispatch(toggleSelectedArr({ name: "status", value: validStatus }));
      }
      const validGender = arrParams.filter((tag) =>
        tagsForGender.includes(tag)
      );
      if (!!validGender.length) {
        const changeGenderTags = validGender;
        dispatch(
          toggleStateArr({ StateName: "gender", value: changeGenderTags })
        );
        dispatch(
          toggleSelectedArr({ name: "gender", value: changeGenderTags })
        );
        dispatch(
          toggleSelectedArr({ name: "status", value: changeGenderTags })
        );
      }
      dispatch(setIsParamsExist(true));
    }
    if (!thirdSegment && lang && city && !paramsExist) {
      dispatch(setIsParamsExist(true));
    }
  }, [lang, city]);

  const url = window.location.pathname;
  const segments = url.split("/");
  const isMainPage = useSelector(getIsMainPage);
  useEffect(() => {
    if (!url.includes("lk")) {
      if (lang && city && segments.length < 3 && isMainPage) {
        if (lang === "en" && city === "prague") {
          console.log("navigate to /");
          navigate(`/`);
        } else {
          console.log("navigate to /lang/city");
          navigate(`/${lang}/${currentCity.slug}`);
        }
      }
    }
  }, [lang, city, isMainPage]);

  const isLoading = useSelector(getIsLoading);
  const title = useSelector(getTitle);
  const result = GetProfilesUrl();

  const sortedStatusValue = useSelector(getStatusSorted);
  const sortedGenderValue = useSelector(getGenderSorted);
  const sortBy = useSelector(getTypeSorted);
  const page = useSelector(getPage);
  const isFilterSelected = useSelector(isFilterSearch);
  const statusFilterValue = useSelector(filterStatus);
  const genderFilterValue = useSelector(filterGender);
  const filterValue = useSelector(showFilter);
  const placeValue = useSelector(filterPlace);
  const priceValue = useSelector(getPriceForModels);
  const ageValue = useSelector(getAgeForModels);
  const searchByName = useSelector(getSearchValue);
  const sortedByGenderOrStatus = useSelector(getAllSorted);

  useEffect(() => {
    if (lang && city) {
      if (!filterValue) {
        if (!!sortedByGenderOrStatus.length) {
          const result = sortedByGenderOrStatus.map((item) => {
            switch (item) {
              case "couples":
                return "couple";
              case "male":
                return "guys";
              case "trans":
                return "trance";
              case "female":
                return "";
              case "video":
                return "";
              case "travel":
                return "";
              case "escort":
                return "";
              case "gf":
                return "";
              default:
                return item;
            }
          });
          if (result.filter((item) => !!item).length) {
              navigate(`/${lang}/${currentCity.slug}/${result.join("-")}`);
          }
        }
        if (!sortedByGenderOrStatus.length) {
          console.log("navigate");
          if (lang === "en" && city === "prague") {
            navigate("/");
          } else {
            console.log(currentCity.slug)
            navigate(`/${lang}/${currentCity.slug}`);
          }
        }
      }
    }
  }, [sortedByGenderOrStatus, filterValue]);

  const isShortFilterShow = useSelector(showShortFilter);
  const valueResultFilter = useSelector(getResultFilterString);

  useEffect(() => {
    if (!filterValue && !isShortFilterShow && currentCity.id && paramsExist) {
      if (page === 1) {
        dispatch(setIsLoading(false));
      }
      
      if (page > 1) {
        dispatch(updateAllModels([...Array(12)]));
      }

      axios
        .request({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
          url:
            baseUrl +
            "profiles" +
            result.params +
            `&city=${currentCity.id}&lang=${lang}`,
        })
        .then((response) => {
          const data = response.data.profiles;
          const dataMeta = response.data.meta;
          const perPage = dataMeta.per_page;
          const totalProfiles = dataMeta.total;
          const currentPage = dataMeta.current_page;
          const isLoadMore = perPage * currentPage < totalProfiles;

          if (page === 1) {
            dispatch(setAllModels(data));
            dispatch(setHasMorePage(isLoadMore));
          } else {
            dispatch(updateAllModels(data));
            dispatch(filterAllModels());
            dispatch(setHasMorePage(isLoadMore));
          }
          if (data.length === 0) {
            setIsDataEmpty(true);
          } else {
            setIsDataEmpty(false);
          }
          const filterData = response.data.filters;
          dispatch(
            setDefaultRange({
              name: "price",
              min: filterData.price_min,
              max: filterData.price_max,
            })
          );
          dispatch(
            setDefaultRange({
              name: "age",
              min: filterData.age_min,
              max: filterData.age_max,
            })
          );
          dispatch(
            setDefaultRange({
              name: "weight",
              min: filterData.weight_min,
              max: filterData.weight_max,
            })
          );
          dispatch(
            setDefaultRange({
              name: "height",
              min: filterData.height_min,
              max: filterData.height_max,
            })
          );

          if (windowWidth !== 0 && windowWidth < 998) {
            dispatch(
              rangeChange({
                name: "price",
                min: filterData.price_min,
                max: filterData.price_max,
              })
            );
            dispatch(
              rangeChange({
                name: "age",
                min: filterData.age_min,
                max: filterData.age_max,
              })
            );
            dispatch(
              rangeChange({
                name: "weight",
                min: filterData.weight_min,
                max: filterData.weight_max,
              })
            );
            dispatch(
              rangeChange({
                name: "height",
                min: filterData.height_min,
                max: filterData.height_max,
              })
            );
          }
          const result = extractKeysAndNumbers(filterData);
          dispatch(setNotDisabledCheckbox(result));
          dispatch(setIsLoading(true));
        })
        .then(dispatch(setIsRangeLoad(true)))
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    paramsExist,
    placeValue,
    searchByName,
    ageValue,
    priceValue,
    sortedByGenderOrStatus,
    sortBy,
    page,
    currentCity.id,
    filterValue,
    isShortFilterShow,
    lang,
  ]);

  const postCity = t("incity")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : " "
  );

  const postTitle =
    t("h1main")?.replace(
      /%([^%]+)%/g,
      currentCity.title ? currentCity.title : " "
    ) +
    " " +
    postCity;

  const postTitleMobile = t("h1main")?.replace(/%([^%]+)%/g, "");

  const titleString =
    title.length > 0
      ? title
          .filter((item) => !!item)
          .map((item) => t(item))
          .join(", ")
      : "";

  const titleParams = !!titleString ? `${titleString} ${postCity}` : postTitle;
  const mobileTitleParams = !!titleString ? `${titleString}` : postTitleMobile;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (isShortFilterShow) {
      const resultUrl = `${baseUrl}profiles/count?city=${currentCity.id}`;

      const searchValue = searchByName && `&search=${searchByName}`;

      const paramsSortedByStatusValue =
        sortedStatusValue.length && !isFilterSelected > 0
          ? "&" + sortedStatusValue.map((item) => item + "=1").join("&")
          : statusFilterValue.length > 0 && isFilterSelected
          ? "&" + statusFilterValue.map((item) => item + "=1").join("&")
          : "";

      const paramsSortedByGenderValue =
        sortedGenderValue.length && !isFilterSelected > 0
          ? "&gender=" + sortedGenderValue.join(",")
          : genderFilterValue.length > 0 && isFilterSelected
          ? "&gender=" + genderFilterValue.join(",")
          : "";

      const params = `?${paramsSortedByStatusValue}${paramsSortedByGenderValue}${
        valueResultFilter.length > 0 ? valueResultFilter : ""
      }${searchValue}`;

      axios
        .request({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
          url: resultUrl + params,
        })
        .then((response) => {
          setSearchCount(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    searchByName,
    sortedStatusValue,
    isShortFilterShow,
    sortedGenderValue,
    sortedStatusValue,
    statusFilterValue,
    valueResultFilter,
  ]);
  const navigate = useNavigate();

  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  const checkboxNavTemplate = (i) => (
    <CheckboxNavigation
      key={i.id}
      id={i.id}
      checked={i.checked}
      title={i.title}
      path={i.path}
      value={i.value}
      name={i.name}
      filter_disabled={i.forDisabled}
      isLink={i.isLink}
      translateKey={i.translateKey}
      important
    />
  );
  const profilesCountValue = useSelector(getProfilesCount);
  const profilesFilterCountValue = useSelector(getProfilesFilterCount);
  const countriesData = useSelector(getCountriesData);
  const [currentActive, setCurrentActive] = useState("");
  const [mobileCityOpen, setMobileCityOpen] = useState(false);
  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

  return (
    <section className="navigation__container">
      <div className={"navigation__top"}>
        {isJsdom || windowWidth > 767.98 ? (
          <>
            <h1>{!isFilterSelected ? titleParams : t("matching")}</h1>

            <Notifications />

            <ButtonLink
              onClick={() => {
                profilesCountValue && dispatch(setStoriesMap(true));
              }}
              clazz={`button_outline--black`}
              size={"s"}
              disabled={isDataEmpty || profilesCountValue === 0 || profilesFilterCountValue === 0}
            >
              {t("onmap")} <Icon size={"m"} spritePath={"map"} />
            </ButtonLink>
          </>
        ) : (
          <>
            <h1>
              {!isFilterSelected
                ? mobileTitleParams + " " + t("in")
                : t("matching")}{" "}
              {
                <span
                  className="select-city--word"
                  onClick={() => setMobileCityOpen((prev) => !prev)}
                >
                  {currentCity.title}
                </span>
              }
            </h1>

            <SelectCountry
              data={countriesData}
              name={"city-create"}
              clazz={"select-city"}
              withoutArrow
              type={"flag"}
              defaultTitle={""}
              currentActiveIndex={currentActive}
              isMain={true}
              activeCountry={currentCity.icon}
              setActive={setCurrentActive}
              open={mobileCityOpen}
              withoutTitle={true}
            />

            <div className={"navigation__top-wrapper"}>
              <ButtonLink
                anchor={true}
                onClick={() => {
                  profilesCountValue && dispatch(setStoriesMap(true));
                }}
                square={true}
                href={"#models-map"}
                clazz={`button_outline--black`}
                size={"s"}
                disabled={profilesCountValue === 0}
              >
                <Icon spritePath={"map"} />
              </ButtonLink>

              <Notifications />
            </div>
          </>
        )}
      </div>

      <div className="navigation__bottom">
        <nav className="navigation__nav">
          {ShortFilterData().options.map((i) => checkboxNavTemplate(i))}
          {!isJsdom ? (
              <Dropdown
              title={ShortFilterData().main_title}
              size={"xs"}
              clazzWrapper={"navigation__more"}
          >
            {ShortFilterData().options.map((i) => checkboxNavTemplate(i))}
          </Dropdown>
          ) : null}

          {windowWidth < 767.98 ? (
            <>
              <Button
                clazz={"button--secondary navigation__mobile-filter"}
                size={"xs"}
                onClick={() => dispatch(showShort())}
              >
                <Icon size={"m"} spritePath={"filter2"} />
                {t("smartfilter")}{" "}
                <Icon size={"s"} spritePath={"chevrone-down"} />
              </Button>
            </>
          ) : null}
        </nav>

        {!!sortedByGenderOrStatus.length && (
          <Button
            onClick={() => {
              dispatch(cleanSorted());
              dispatch(resetState("gender"));
              dispatch(resetSelected("gender"));
              dispatch(resetState("status"));
              dispatch(resetSelected("status"));
              dispatch(resetStateCount("status"));
              dispatch(
                resetAccordionByStatus({
                  accordionName: "status",
                  arr: ["couple", "male", "trans", "guys"],
                })
              );
              dispatch(
                resetAccordionByStatus({
                  accordionName: "gender",
                  arr: ["couple", "male", "trans"],
                })
              );
              dispatch(resetStateCount("gender"));
              dispatch(resetStateCount("sex"));
              dispatch(resetStateAcc("status"));
              dispatch(resetToggleAcc("status"));
              dispatch(cleanSorted());
            }}
            size={"s"}
            title={t("reset")}
            clazz="header__reset hover"
          >
            {t("reset")}
          </Button>
        )}

        <Select
          options={filterNavigation}
          mainIcon={"sort"}
          clazzSvg={"icon-s"}
          clazz={"button--secondary"}
          arrowSize={"s"}
          size={"xs"}
          sorted={true}
          name={"sort"}
        ></Select>
      </div>

      <div className="grid-cards">
        {isLoading && !isDataEmpty && models.map((data, index) => {
            return !!data ? (
              <GirlCard big props={data} size={"m"} key={data.id} />
            ) : (
              <Skeleton key={index}></Skeleton>
            );
          })}
        {isLoading ? <StoriesSlider size={"s"} /> : null}
        {isDataEmpty && <h2 className="navigation__empty">{t("notfound")}</h2>}
        {!isLoading && skeletons}
      </div>

      {morePage && (
        <Button
          type="button"
          onClick={() => dispatch(setPage())}
          clazz={"navigation__show-more button--green"}
          size={"l-forever"}
        >
          {t("showmore")}
        </Button>
      )}
    </section>
  );
};

export default Navigation;
