import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import {
  getGalleryIndexMedia,
  getGalleryStatus,
  setGalleryStatus,
} from "@/stores/slices/popupSlice";
import { Video} from "@/components/ui";
import {useSelector} from "react-redux";
import useLadyService from "@/services/LadyService";

import "./gallery.scss";
import EroVideo from "../../pages/accounts/createProfile/pageComponent/stepSection/media/EroVideo";
import EroMedia from "../../pages/accounts/createProfile/pageComponent/stepSection/media/EroMedia";
import ShowMoreContent from "../../pages/modelPage/components/showMoreContent/ShowMoreContent";
import {
  getGalleryMediaEroPhoto,
  getGalleryMediaEroVideo,
  getGalleryMediaInterior,
  getGalleryMediaPhoto,
  getGalleryMediaVideo,
  setAuthorisationAccessType,
  setAuthorizationStatus, setErocontentAccessStatus,
  setGalleryMediaEroPhoto,
  setGalleryMediaEroVideo,
  setGalleryMediaIndex,
  setGalleryMediaInterior,
  setGalleryMediaPhoto,
  setGalleryMediaVideo
} from "../../../stores/slices/popupSlice";
import {Popup} from "../index";

const Gallery = ({name, media, ero}) => {
  const {dispatch, windowWidth, t, setMediaSize, userType} = useLadyService();
  let maxCountPhoto = useSelector(getGalleryMediaPhoto)
  let maxCountVideo = useSelector(getGalleryMediaVideo)
  let maxCountInterior = useSelector(getGalleryMediaInterior)
  let maxCountEroPhoto = useSelector(getGalleryMediaEroPhoto)
  let maxCountEroVideo = useSelector(getGalleryMediaEroVideo)
  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
  const postAltphoto = t("altphotoprofile").replace(/%([^%]+)%/g, name);

  const galleryStatus = useSelector(getGalleryStatus);
  const currentGalleryIndex = useSelector(getGalleryIndexMedia);

  const [maxSlideCount, setMaxSlideCount] = useState(null);

  const [mediaFiles, setMediaFiles] = useState([]);
  const [eroModel, setEroModel] = useState([]);

  useEffect(() => {
    setMediaFiles(media);

    if(media?.photo?.length > 0){
      dispatch(setGalleryMediaPhoto(media.photo.length))
    }

    if(media?.video?.length > 0){
      dispatch(setGalleryMediaVideo(media.video.length))
    }

    if(media?.interior?.length > 0){
      dispatch(setGalleryMediaInterior(media.interior.length))
    }

    if(ero?.data?.photo?.length > 0){
      dispatch(setGalleryMediaEroPhoto(ero.data.photo.length))
    }

    if(ero?.data?.video?.length > 0){
      dispatch(setGalleryMediaEroVideo(ero.data.video.length))
    }

    if(ero?.data?.photo?.length > 0 ||
      ero?.data?.video?.length > 0){
      setEroModel(ero.data);
    }
  }, [
    media,
    media.photo.length,
    media.video.length,
    media.interior.length,
    ero,
  ]);

  useEffect(() => {
    setMaxSlideCount(
      maxCountPhoto
      + maxCountVideo
      + maxCountInterior
      + maxCountEroPhoto
      + maxCountEroVideo
    );
  }, [
    maxCountPhoto,
    maxCountVideo,
    maxCountInterior,
    maxCountEroPhoto,
    maxCountEroVideo
  ]);

  const checkEroAccess = () => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("ero"));
    } else if (ero?.data?.hide) {
      dispatch(setErocontentAccessStatus(true));
      dispatch(setGalleryStatus(false))
    }
  }

  const MediaTemplate = ({data, type, alt}) => {
    const regex = /([^.]+)$/;
    const extension = data?.match(regex)[1];

    return extension === ("mp4" || "webm") || type === "video" ? (
      <Video
        title={alt}
        onClick={(e) => e.stopPropagation()}
        src={data}
        controls={true}
      />
    ) : (
      <img
        src={setMediaSize(data, "xl")}
        onClick={(e) => e.stopPropagation()}
        title={alt}
        alt={alt}
      />
    );
  };

  const MediaTemplateEro = ({data, type, alt, hide}) => {
    return (
      <>
        {!hide ? null : <ShowMoreContent />}

        {type === "video" ? (
          <EroVideo
            onClick={(e) => e.stopPropagation()}
            controls
            title={t("altvideoprofile")}
            alt={t("altvideoprofile")}
            url={data}
          />
        ) : (
          <EroMedia
            src={data}
            title={alt}
            alt={alt}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </>
    );
  };

  const handleClose = () => {
    dispatch(setGalleryStatus(false))
  }

  return (
    <>
      {windowWidth > 767.98 ? (
        <Popup
          id="simpleGalleryPopup"
          open={!!galleryStatus}
          setOpen={handleClose}
          wrapperClazz={"gallery"}
          container={'none'}
        >

          <span className="gallery__numbers">
            {currentGalleryIndex + 1}/{maxSlideCount}
          </span>

          {!isJsdom && galleryStatus ? (
            <Swiper
              className={"gallery__items"}
              modules={[Navigation]}
              slidesPerView={1}
              spaceBetween={10}
              onInit={(swiper) => swiper.slideTo(currentGalleryIndex)}
              navigation={{
                nextEl: ".gallery__button-next",
                prevEl: ".gallery__button-prev",
              }}
              onActiveIndexChange={function () {
                let index = this.realIndex;
                dispatch(setGalleryMediaIndex(index));
              }}
            >
              <button
                slot={"container-start"}
                onClick={(e) => e.stopPropagation()}
                className="swiper-button-prev gallery__button-prev"
              ></button>

              {!!mediaFiles?.photo?.length ?
                mediaFiles.photo.map((item) => (
                  <SwiperSlide
                    className={`gallery__item`}
                    onClick={() => dispatch(setGalleryStatus(false))}
                  >
                    <MediaTemplate
                      data={item}
                      alt={postAltphoto}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </SwiperSlide>
                ))
                : null}

              {!!mediaFiles?.video?.length ?
                mediaFiles.video.map((item) => (
                  <SwiperSlide
                    className={`gallery__item`}
                    onClick={() => dispatch(setGalleryStatus(false))}
                  >
                    <MediaTemplate
                      data={item}
                      type={"video"}
                      alt={`${name}`}
                    />
                  </SwiperSlide>
                ))
                : null}

              {!!mediaFiles?.interior?.length ?
                mediaFiles.interior.map((item) => (
                  <SwiperSlide
                    className={`gallery__item`}
                    onClick={() => dispatch(setGalleryStatus(false))}
                  >
                    <MediaTemplate
                      data={item}
                      alt={`${t("aginterior")} ${name}`}
                    />
                  </SwiperSlide>
                ))
                : null}

              {!!eroModel?.photo?.length ?
                eroModel.photo.map((item) => {
                  return (
                    <SwiperSlide
                      key={item}
                      className={`gallery__item`}
                      onClick={checkEroAccess}
                    >
                      <MediaTemplateEro
                        data={item}
                        hide={eroModel.hide}
                        type={"image"}
                        alt={`${t("aginterior")} ${name}`}
                      />
                    </SwiperSlide>
                  );
                })
                : null}

              {!!eroModel?.video?.length ?
                eroModel.video.map((item) => {
                  return (
                    <SwiperSlide
                      key={item}
                      className={`gallery__item model-main__private-slide`}
                      onClick={checkEroAccess}
                    >
                      <MediaTemplateEro
                        data={item}
                        type={"video"}
                        hide={eroModel.hide}
                        alt={`${t("aginterior")} ${name}`}
                      />
                    </SwiperSlide>
                  );
                })
                : null}

              <button
                slot={"container-end"}
                onClick={(e) => e.stopPropagation()}
                className="swiper-button-next gallery__button-next"
              ></button>
            </Swiper>
          ) : null}

          <span className={"gallery__caption"}>{name}</span>
        </Popup>
      ) : null}
    </>
  );
};

export default Gallery;
