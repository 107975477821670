import {Popup} from "../index";
import {Button} from "../../ui";
import useLadyService from "../../../services/LadyService";

const ConfirmChangeTariffPopup = ({open, setOpen, tariffPrice, discountPrice, tariffName}) => {
  const {t} = useLadyService();
// TODO: Доделать

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      // onSubmit={handleSubmit(onSubmit)}
      container={'div'}
      clazz={'text-center'}
    >
      <h2>Смена тарифа</h2>

      <p>Новый тариф</p>

      <h3 className={'color-green'}>{tariffName}</h3>

      <div className="popup-form__fake-input fd-column justify-center mb-20 bg-color-200">
        {discountPrice ? (
          <div className={'d-flex justify-sb w-100 align-center'}>
            <p className={'m-0'}>Первый месяц</p>
            <span className={"title_h3 currency--erocoin"}>{discountPrice}</span>
          </div>
        ) : null}

        <div className={'d-flex justify-sb w-100 align-center'}>
          <p className={'m-0'}>{t("everymonth")}</p>
          <span className={"title_h3 currency--erocoin"}>{tariffPrice}</span>
        </div>
      </div>

      <div className="d-flex gap-8">
        <Button
          size={"l-forever"}
          buttonType={"reset"}
          clazz={"button--primary w-100"}
          onClick={() => setOpen(false)}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          onClick={() => setOpen(false)}
          buttonType={"submit"}
          clazz={"button--green w-100"}
        >
          {t("confirm")}
        </Button>
      </div>
    </Popup>
  )
}

export default ConfirmChangeTariffPopup