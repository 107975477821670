import example1 from "@/assets/img/examplePhotos/verExamplePlus.svg";
import { Button, Icon } from "@/components/ui";
import unilabs20 from "@/assets/img/unilabs.svg";
import unilabs20light from "@/assets/img/unilabs.svg";
import { useEffect, useState } from "react";

import "../verAds/verAds.scss";
import ExampleImage from "../exampleImage/ExampleImage";
import useLadyService from "@/services/LadyService";
import UploadImage from "../uploadImage/UploadImage";
import { Link } from "react-router-dom";
import { setHealthStatus, setVerStatus } from "@/stores/slices/lkIndiSlice";
import { DiscountToHealthPopup } from "@/components/popups";
import PhotoUploadSuccess from "../photoUploadSuccess/PhotoUploadSuccess";
import {
  setSuperVerStatus,
} from "@/stores/slices/lkIndiSlice";

const VerHealthNew = ({
  setDataInfo,
  healthRef,
  isHealthBoosted,
  healthy_at,
  data: dataInfo = {},
  setCurrentType,
  isCreatePage = false,
  isVerificationExist,
  setIsVerificationExist,
  isBoosted,
  setIsBoosted,
}) => {
  const {
    is_super_verified = false,
    healthy_photo = {},
    super_verified_at = "",
    verified_at = "",
    verify_photo,
    healthy_reason,
  } = dataInfo;

  const data = is_super_verified ? verify_photo.data || {} : {};

  const { data: dataHealthy = {} } = healthy_photo;
  const { link = null, moderated } = data;
  const {
    link: linkHealthy = null,
    moderated: moderatedHealthy,
    name,
  } = dataHealthy;
  const { t, theme, dispatch, windowWidth } = useLadyService();

  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  const src = theme === "dark" ? unilabs20light : unilabs20;
  const handleOpenDiscount = () => {
    setIsOpenDiscount(true);
  };
  const isMobile = windowWidth < 767.98;

  const [image, setImage] = useState(link);

  const [removeLink, setRemoveLink] = useState(link);
  const [imageAnalysis, setImageAnalysis] = useState(linkHealthy);
  const [removeLinkHealthy, setRemoveLinkHealthy] = useState(linkHealthy);

  useEffect(() => {
    if (link) {
      setImage(link);
    }
  }, [link]);

  useEffect(() => {
    if (image) {
      dispatch(setVerStatus(true));
      dispatch(setSuperVerStatus(true));
    } else {
      dispatch(setSuperVerStatus(false));
    }
  }, [image]);

  useEffect(() => {
    if (imageAnalysis) {
      dispatch(setHealthStatus(true));
    } else {
      dispatch(setHealthStatus(false));
    }
  }, [imageAnalysis]);

  const MobileWrapper = ({ children }) => {
    if (isMobile) {
      return <div className="d-flex gap-8 align-center">{children}</div>;
    } else {
      return children;
    }
  };

  return (
    <>
      <DiscountToHealthPopup
        open={isOpenDiscount}
        setOpen={setIsOpenDiscount}
      />

      {isMobile && <hr />}

      <div className={`${isMobile ? "" : "verads control"}`} id={"super-verification"} ref={healthRef}>
        <div className="verads__title mt-16">
          <div className="d-flex fd-column">
            <b className="fz-16">{t("superidphoto")}</b>
            <span>{"(" + t("matchmediahelath") + ")"}</span>
          </div>

          <MobileWrapper>
            <span
              title={t("photosverified")}
              className={`${isMobile ? "" : "ml-auto"} model__icon`}
            >
              <Icon spritePath={"verify-fill"} size={"l"} />
              {t("verification")}
            </span>

            <span className="color-green">+</span>

            <span title={t("advantagehealth")} className={"model__icon"}>
              <Icon spritePath={"health-fill"} size={"l"} />
              {t("healthy")}
            </span>
          </MobileWrapper>
        </div>
        <span className="color-600 mt-12">{t("healthcontrolrec")}</span>

        {!image && (
          <div className="verads__photo mt-12">
            <div className="verads__examplePhoto text-center">
              {t("example")}
              <br></br>
              {t("superidphoto")}
              <img src={example1} alt={t("example")} />
            </div>
            <UploadImage
              setData={setImage}
              mainText={t("uploadphoto")}
              subText={t("photodisplayid")}
              type={"is_super_verify"}
              setRemoveLink={setRemoveLink}
            />
          </div>
        )}

        {!!image && (
          <div className="verads__photo mt-12">
            <ExampleImage
              setDataInfo={setDataInfo}
              image={image}
              setImage={setImage}
              removeLink={removeLink}
            />

            <PhotoUploadSuccess
              setIsVerificationExist={setIsVerificationExist}
              isVerificationExist={isVerificationExist}
              isCreatePage={isCreatePage}
              moderatedVerify={moderated}
              type={"superPhoto"}
              at={super_verified_at || verified_at}
              isBoosted={isBoosted}
              setIsBoosted={setIsBoosted}
            />

            {imageAnalysis && (
              <ExampleImage
                type="healthy"
                name={name}
                image={imageAnalysis}
                imageType={'pdf'}
                setImage={setImageAnalysis}
                removeLink={removeLinkHealthy}
              />
            )}

            {!imageAnalysis && (
              <UploadImage
                pdf={true}
                setData={setImageAnalysis}
                mainText={t("requestanalysis")}
                subText={t("testtodownload")}
                type={"is_healthy"}
                setRemoveLink={setRemoveLinkHealthy}
              />
            )}

            {imageAnalysis && (
              <PhotoUploadSuccess
                moderatedHealthy={moderatedHealthy}
                isHealthBoosted={isHealthBoosted}
                setCurrentType={setCurrentType}
                healthy_reason={healthy_reason}
                type={"analiz"}
                at={healthy_at}
              />
            )}
          </div>
        )}

        <p>
          {t("healthimpornantinfo")}
          {"."}
          <br></br>
          {t("Status")}{" "}
          <Link
            to={"/guide#healthControl"}
            className={"color-green hover-line"}
          >
            {'"' + t("healthy") + '"'}
          </Link>{" "}
          {t("healthforinfo")}{'.'}
        </p>
        <div className={`d-flex gap-16 justify-sb align-center mt-12 ${isMobile ? "fd-column" : ""}`}>

          {isMobile && <hr className="w-100"></hr>}

          <img src={src} alt="unilabs" className="h-48 color-main" />

          <span className="fz-13">{t("discountstds")}</span>

          <Button
            title={t("qrcodediscount")}
            clazz={"button--primary justify-center min-w-160"}
            size={"s"}
            onClick={handleOpenDiscount}
          >
            {t("qrcodediscount")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default VerHealthNew;
