import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";

import { Button, ButtonLink, Icon } from "@/components/ui";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import useLadyService from "@/services/LadyService";

import GirlCardButtons from "./components/GirlCardButtons";
import GirlCardSlider from "./components/GirlCardSlider";
import GirlCardIconsAndTags from "./components/GirlCardIconsAndTags";

import "./girlCard.scss";
import { useSelector } from "react-redux";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { Checkbox } from "../../ui";
import GirlCardAdvantages from "./components/GirlCardAdvantages";

const GirlCard = ({ props, big, agencySelectCheckbox = [], setAgencySelectCheckbox = false, checkbox = false, clazz, edit, status, targetBlank = false }) => {
  const {
    gender,
    orientation,
    id,
    slug,
    name,
    rating,
    address,
    city,
    age,
    height,
    weight,
    breast,
    penis_size,
    prices,
    languages,
    services,
    is_online,
    is_individual,
    is_top,
    is_elite,
    is_escort,
    is_healthy,
    is_verified,
    is_pornstar,
    is_new,
    tag_video,
    tag_friends,
    tag_booking,
    tag_bdsm,
    ready_travel,
    count_photos,
    count_videos,
    photos,
    is_favorite,
    healthy_at,
    verified_at,
    has_ero,
    price,
    main_photo,
    media,
    statistic = {},
  } = props;

  const { favorites } = statistic;

  const { windowWidth, dispatch, setMediaSize, t, travelVariants, userType, lang } = useLadyService();

  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };

  const linkRef = useRef(null);

  const [isHover, setIsHover] = useState(false);

  const setServices = Array.from(new Set(services));
  const currentCity = useSelector(getUserCurrentCity);

  const isAgency = userType === "agency"

  let newArray =
    !!services?.length && !services[0]["category_id"]
      ? setServices
      : !!services?.length
      ? services
          .map((category) => {
            return category.services.map((service) => service.slug);
          })
          .flat()
      : [];


  const ParamTemplate = (translateKey, param, decimal) => (
    <span className={"girl-card__param"}>
      <span>{t(translateKey)}</span>
      {`${param} ${decimal ? t(decimal) : ""}`}
    </span>
  );

  const PriceTemplate = (clazz, translateKey, price) => (
    <span className={`girl-card__price--${clazz}`}>
      <span>{t(translateKey)}</span>
      {price}{lang === "cz" ? "Kč" : "€"}
    </span>
  );

  const handleAgencyCheckbox = (slug) => {
    if(setAgencySelectCheckbox) {
      if(!agencySelectCheckbox.includes(slug)) {
        setAgencySelectCheckbox(prev => [...prev, slug])
      } else {
        setAgencySelectCheckbox(prev => prev.filter(item => item !== slug))
      }
    }
  }

  const IconTemplate = (translateKey, icon) => {
    let clazz = translateKey === "erocontent" ? is_top? "" : "color-green" : "";
    let travelTitle = translateKey === "travel" ? `: ${travelVariants[ready_travel]}` : "";
    return (
      <Icon
        square
        clazz={clazz}
        title={`${t(translateKey)}${travelTitle}`}
        spritePath={icon}
        size={"l"}
      />
    );
  };

  const link = big ? `/${lang}/profile/${slug}` : `/${lang}/lk/indi/profile/${slug}/edit`;

  const topClass = is_top ? " girl-card--top" : "";

  const eliteClass = is_elite ? " girl-card--elite" : "";

  const statusClass = edit ? (!status ? " not_active" : "") : "";

  const setBigClass =
    (windowWidth < 767.98 && windowWidth !== 0) || big ? " girl-card__big" : "";

  const setClass = clazz ? ` ${clazz}` : "";

  const classes = `girl-card${topClass}${eliteClass}${statusClass}${setBigClass}${setClass}`;

  const photo = !!main_photo
    ? main_photo
    : !!media?.photo?.length
    ? media.photo[0]
    : null;

  const showAdditionalInfo = ((isJsdom && (big !== undefined && big )) || !isJsdom)
  return (
    <div className={classes}>
      <div className={`girl-card__wrapper`}>
        {edit ? (
          <>
            <div
              className="girl-card__slider _edit"
              onMouseLeave={() => setIsHover(false)}
              onMouseEnter={() => setIsHover(true)}
            >
              <Button clazz={`girl-card__favorite`}>
                <span className="color-green">{favorites}</span>
                <Icon size={"l"} spritePath={"favorite-fill"}/>
              </Button>

              <ButtonLink
                target={'_blank'}
                size={"xs"}
                href={`/${lang}/lk/indi/profile/${slug}/edit`}
                title={t("edit") + " " + t("onpage") + " " + t("myads")}
                clazz={`button--secondary girl-card__edit`}
              >
                <Icon spritePath={"edit-2"} size={"xs"}/>
                {t("edit")}
              </ButtonLink>

              <div
                onClick={() => linkRef.current.click()}
                className="swiper-slide girl-card__slide"
              >
                {!!photo ? (
                  <img
                    src={
                      big
                        ? windowWidth < 599.98
                          ? setMediaSize(photo, "s2")
                          : setMediaSize(photo, "m")
                        : setMediaSize(photo, "s2")
                    }
                    alt={t("altphoto", {GirlName: name, City: currentCity.name })}
                  />
                ) : null}
              </div>

              <div className="girl-card__descr">
                <div className="girl-card__tags-wrapper">
                  <GirlCardIconsAndTags
                    props={{
                      gender,
                      orientation,
                      is_individual,
                      is_healthy,
                      is_verified,
                      is_pornstar,
                      is_new,
                      tag_bdsm,
                      healthy_at,
                      verified_at,
                    }}
                  />
                </div>
              </div>
              {isAgency && (
                <Checkbox checked={agencySelectCheckbox.includes(slug)} title={""}
                        onClick={() => handleAgencyCheckbox(slug)}/>
              )}
            </div>
          </>
        ) : (
          <GirlCardSlider
            isHover={isHover}
            setIsHover={setIsHover}
            checkbox={checkbox}
            linkRef={linkRef}
            props={{
              gender,
              orientation,
              id,
              slug,
              name,
              is_healthy,
              is_verified,
              count_photos,
              count_videos,
              photos,
              is_favorite,
              has_ero,
              is_individual,
              is_new,
              tag_bdsm,
              is_pornstar,
              verified_at,
              healthy_at,
              big,
            }}
          />
        )}
        <div className="girl-card__info-wrapper">
          {showAdditionalInfo ? (
          <div className="girl-card__age">
            {age} <span>{t("years")}</span>
          </div>
          ) : null}

          <div className="girl-card__info">
            <Link
              target={targetBlank ? "_blank" : ''}
              to={edit ? link : `/${lang}/profile/${slug}`}
              ref={linkRef}
              onMouseLeave={() => setIsHover(false)}
              onMouseEnter={() => setIsHover(true)}
              title={
                is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`
              }
              className={`girl-card__name${isHover ? " _hover" : ""}${
                is_online ? " _online" : " _offline"
              }`}
            >
              {name}
            </Link>

            {!isJsdom && !!address ? (
              <Link
                to={"#"}
                title={t("onmap")}
                className="girl-card__address"
                onClick={() => toggleMapOpen(slug)}
              >
                <Icon spritePath={"map"} size={"xs"}/>
                {city}, {address}
              </Link>
            ) : !!city ? (
              <span title={t("onmap")} className="girl-card__address">
                <Icon spritePath={"map-pin"} size={"xs"}/>
                {city}
              </span>
            ) : null}
          </div>
        </div>

        <div className="girl-card__group">
          {showAdditionalInfo && (!!height || !!weight || !!breast || !!penis_size) ? (
            <div className="girl-card__params">
              {!!height && ParamTemplate("height", height, "cm")}
              {!!weight && ParamTemplate("weight", weight, "kg")}
              {!!penis_size && ParamTemplate("penissize", penis_size)}
              {!!breast && ParamTemplate("breastsize", breast)}
            </div>
          ) : null}

          <div className="girl-card__modes">
            <GirlCardIconsAndTags
              props={{
                gender,
                orientation,
                is_individual,
                is_healthy,
                is_verified,
                is_pornstar,
                is_new,
                tag_bdsm,
                healthy_at,
                verified_at,
              }}
            />

            {showAdditionalInfo && !!tag_video ? IconTemplate("video", "video-play") : null}
            {showAdditionalInfo && !!tag_friends ? IconTemplate("girlfriend", "girlfriend") : null}
            {showAdditionalInfo && !!has_ero ? IconTemplate("erocontent", "pants") : null}
            {showAdditionalInfo && !!tag_booking ? IconTemplate("onlineorder", "pay-content") : null}
            {showAdditionalInfo && !!ready_travel ? IconTemplate("travel", "travel") : null}
            {showAdditionalInfo && !!is_escort ? IconTemplate("escort", "escort") : null}

            {showAdditionalInfo && !!newArray.length
              ? newArray.map((item) => {
                return <span key={item}>{t(`${item}-s`)}</span>;
              })
              : null}
          </div>

          {!isJsdom && (!!rating || !!languages?.length) && windowWidth < 599.98 ? (
            <GirlCardAdvantages
              rating={rating}
              languages={languages}
            />
          ) : null}
        </div>

        <div className="girl-card__prices">
          {edit && !!price?.incall
            ? PriceTemplate("private", "incall", price?.incall)
            : !!prices?.incall && !edit
              ? PriceTemplate("private", "incall", prices?.incall)
              : null}

          {edit && !!price?.outcall
            ? PriceTemplate("escort", "outcall", price?.outcall)
            : !!prices?.outcall && !edit
              ? PriceTemplate("escort", "outcall", prices?.outcall)
              : null}

          {edit && !!price?.escort
            ? PriceTemplate("accompaniment", "accompaniment", price?.escort)
            : !!prices?.escort && !edit
              ? PriceTemplate("accompaniment", "accompaniment", prices?.escort)
              : null}
        </div>

        {!isJsdom && (!!rating || !!languages?.length) && windowWidth > 599.98 ? (
          <GirlCardAdvantages
            rating={rating}
            languages={languages}
          />
        ) : null}
        {!isJsdom ? (
        <GirlCardButtons props={props} edit={edit}/>
            ) : null }
      </div>
    </div>
  );
};

export default GirlCard;
