import {createPortal} from "react-dom";
import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import {Button, Icon} from "@/components/ui";
import {Loader} from "../ui";

const Popup = ({
                 open,
                 setOpen,
                 onSubmit,
                 id,
                 clazz,
                 method,
                 container,
                 children,
                 wrapperClazz,
                 isLoading,
                 edit,
}) => {

  const [openAnimation, setOpenAnimation] = useState(false);

  const handleClose = () => {
    if (setOpen && typeof window !== undefined) {
      setOpenAnimation(false);

      setTimeout(() => {
        setOpen();
      }, 200);
    }
  }

  useEffect(()=>{
    if(!!open && typeof window !== undefined){
      setOpenAnimation(true);
    }
  }, [open])

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.isComposing || event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  let CloseButtonTemplate = () => setOpen && (
    <Button
      onClick={handleClose}
      clazz={"button__close icon icon-l icon--close"}
    />
  )

  const containerType = () => {
    switch (container){
      case 'div':
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${edit ? 'popup-form__body__edit' : 'popup-form__body'}${clazz ? ` ${clazz}`: ''}`}
          >


            {children}
            {CloseButtonTemplate()}
          </div>
        )

      case 'none':
        return (
            <>
              {children}
              {CloseButtonTemplate()}
            </>
        )

      default:
        return (
          <form
            method={method}
            onClick={(e) => e.stopPropagation()}
            onSubmit={onSubmit}
            className={`popup-form__body${clazz ? ` ${clazz}`: ''}`}
          >


            {children}
            {CloseButtonTemplate()}
          </form>
        )
    }
  }

  let root = document.querySelector('#root')

    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

    return open && root ? (
        isJsdom ? createPortal(
            <dialog
                id={id}
                open
                onClick={!container ? null : handleClose}
                onCancel={handleClose}
                className={`${wrapperClazz ? wrapperClazz : ''}${openAnimation ? ' _open' : ''}`}
            >
                <Helmet>
                    <html className={"lock"}></html>
                </Helmet>

                {!!isLoading && (
                    <div className="loader__overlay">
                        <Loader height={100} />
                    </div>
                )}

                {containerType()}

            </dialog>,
            root
        ) : (
            <dialog
                id={id}
                open
                className={`${wrapperClazz ? wrapperClazz : ''}${openAnimation ? ' _open' : ''}`}
            >
                {!!isLoading && (
                    <div className="loader__overlay">
                        <Loader height={100} />
                    </div>
                )}

                {containerType()}
            </dialog>
        )
    ) : null;

}
export default Popup