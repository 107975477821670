import { ButtonLink, Tippy, Icon } from "@/components/ui";
import { useState } from "react";
import useLadyService from "@/services/LadyService";

import "./tariffsTable.scss";
import {Button} from "../ui";
import {ConfirmChangeTariffPopup} from "../popups";
import showToast from "../toast/Toast";
import {t} from "i18next";

const TariffsTable = ({refElem}) => {
  const { windowWidth, t, userTariff, userBalance, navigate } = useLadyService();

  const tariff = userTariff?.data?.length ? userTariff?.data[0] : "silver";
  const isSilver = tariff === "silver";
  const isGold = tariff === "gold";
  const isPlatinum = tariff === "platinum";
  const tabCount = tariff === "silver" ? 1 : tariff === "gold" ? 2 : 4;

  const [openPopup, setOpenPopup] = useState(false);
  const [tariffPrice, setTariffPrice] = useState(0);
  const [tariffName, setTariffName] = useState('silver');
  const [tariffDiscount, setTariffDiscount] = useState(0);

  const goldTariffPrice = 25
  const platinumTariffPrice = 100
  const platinumTariffDiscountPrice = platinumTariffPrice && 33
  const checkBalance = (tariffType) => {
    const choosedTariff = (price, name) => {
      setTariffPrice(price)
      setTariffName(name)
      setOpenPopup(true)

      if(name === "Platinum") {
        setTariffDiscount(33)
      } else {
        setTariffDiscount(0)
      }
    }

    const showToastOnPoorWallet = () => {
      showToast({
        type: "insuff",
        variant: 'booking'
      });
    }

    switch (tariffType) {
      case "Gold":
        return  userBalance <= goldTariffPrice ? choosedTariff(goldTariffPrice, 'Gold') :  showToastOnPoorWallet()

      case "Platinum":
        return  userBalance <= platinumTariffDiscountPrice ? choosedTariff(platinumTariffPrice, 'Platinum') :  showToastOnPoorWallet()

      default:
        return ''
    }
  }

  const [tab, setTab] = useState(String(tabCount));
  function changeTabOnClick(num) {
    if (windowWidth < 767.98) {
      setTab(num);
    }
  }

  function changeActiveTab(num) {
    if (windowWidth > 767.98) {
      setTab(num);
    }
  }

    return (
    <>
      <div ref={refElem} data-active-tariff={tab} className="tariffs-table">
        <div className="tariffs-table__header">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__heading"}>{t("options")}</div>
            <div className="tariffs-table__row tariffs-table__buttons">
              <button
                onClick={() => changeTabOnClick('1')}
                onMouseEnter={() => changeActiveTab('1')}
                type={"button"}
                className={`tariffs-table__silver${
                  !!isSilver ? " _active" : ""
                }`}
              >
                Silver
              </button>
              <button
                onClick={() => changeTabOnClick('2')}
                onMouseEnter={() => changeActiveTab('2')}
                type={"button"}
                className={`tariffs-table__gold${
                  !!isGold ? " _active" : ""
                }`}
              >
                Gold
              </button>
              <button
                onClick={() => changeTabOnClick('3')}
                onMouseEnter={() => changeActiveTab('3')}
                type={"button"}
                className={`tariffs-table__platinum${
                  !!isPlatinum ? " _active" : ""
                }`}
              >
                Platinum
              </button>
            </div>
          </div>
        </div>

        <div className="tariffs-table__body">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("guaranteeanon")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("favotitemodels")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("notificationsnew")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("retentionfilters")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("reviewsandcom")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("complaints")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("videochatswithmodel")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}><span className={'currency--erocoin'}>3</span>/{t("min")}</div>
              <div onMouseEnter={() => changeActiveTab('2')}><span className={'currency--erocoin'}>2</span>/{t("min")}
              </div>
              <Tippy
                onMouseEnter={() => changeActiveTab('3')}
                title={`${t("include")} 30 ${t("minit")} ${t("next")} – 3 €`}
              >
                {t("free")}*
              </Tippy>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("erocontentaccess")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>{t("paid")}</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                {t("discount")} 50%
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>{t("free")}</div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("wanotice")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>—</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("orderescort")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("inviteseveral")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("verifytags")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("invitestoparties")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("vipprofile")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("authenticity")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
        </div>

        <div className="tariffs-table__bottom">
          <div
            onMouseEnter={() => changeActiveTab('1')}
            className="tariffs-table__box"
          >
            <p>{t("free")}</p>

            <ButtonLink
              href={`/lk/customer`}
              size={"l-forever"}
              clazz={"button--green w-100"}
              onClick={() => checkBalance('Silver')}
              disabled={isSilver}
            >
              {t(isSilver ? "currenttariff" : "select")}
            </ButtonLink>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('2')}
            className="tariffs-table__box"
          >
            <p><span className={'currency--erocoin'}>{goldTariffPrice}</span>/{t("month")}</p>

            <Button
              size={"l-forever"}
              clazz={"button--green w-100"}
              disabled={isGold}
              onClick={() => checkBalance('Gold')}
            >
              {t(isGold ? "currenttariff" : "select")}
            </Button>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('3')}
            className="tariffs-table__box"
          >
            <p>
              <span className={'currency--erocoin'}>{platinumTariffPrice}</span> <span className={'currency--erocoin'}>100</span>
            </p>
            <Button
              size={"l-forever"}
              clazz={"button--green w-100"}
              disabled={isPlatinum}
              onClick={() => checkBalance('Platinum')}
            >
              {t(isPlatinum ? "currenttariff" : "select")}
            </Button>
            <p className={"p2"}>
              <span className={"title fz-22 fz-20-mob mb-8"}>
                {t("soperaction")}
              </span>
              {t("promotion")}
            </p>
          </div>
        </div>
      </div>

      <ConfirmChangeTariffPopup
        open={openPopup}
        setOpen={setOpenPopup}
        tariffPrice={tariffPrice}
        tariffName={tariffName}
        discountPrice={tariffDiscount}
      />
    </>
    );
    };

    export default TariffsTable;
