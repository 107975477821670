import {Confirmation, StatusElite, Steps} from "./pageComponent/index.js";
import "./createProfile.scss";
import {useRef, useState, useEffect} from "react";
import {Button, ButtonLink} from "@/components/ui";
import {VerificationAndHealthCheck} from "../model/profiles/pageComponets/index.js";
import CountriesFilter from "../components/countriesFilter/CountriesFilter.js";
import useLadyService from "@/services/LadyService";
import {makeRequest} from "../../../../services/makeRequest.js";
import {Loader} from "../../../ui/index.js";
import showToast from "../../../toast/Toast.js";
import {PostAdPopup} from "../../../popups/index.js";
import {
  setOneProfileCoords,
  setOneProfileAddress,
} from "../../../../stores/slices/oneProfileSlice.js";
import MainGlobal from "./pageComponent/stepSection/main/MainGlobal.js";
import AppearanceGlobal from "./pageComponent/stepSection/appearance/AppearanceGlobal.js";
import ServicesGlobal from "./pageComponent/stepSection/services/ServicesGlobal.js";
import TarifsGlobal from "./pageComponent/stepSection/tarifs/TarifsGlobal.js";
import MediaGlobal from "./pageComponent/stepSection/media/MediaGlobal.js";
import {setElitePrice} from "../../../../stores/slices/userSlice.js";
import ChangePhoto from "../../../popups/changePhoto/ChangePhoto.js";

const CreateProfile = () => {
  const {lang, t, navigate, dispatch, token, userType} = useLadyService();

  const [currentStep, setCurrentStep] = useState(1);
  const [availableSteps, setAvailableSteps] = useState([1]);
  const [filled, setFilled] = useState(0);
  const [eliteGlobal, setEliteGlobal] = useState(false);
  const [statusAd, setStatusAd] = useState(false);

  const [isPost, setIsPost] = useState(false);

  const handleOpenPost = () => {
    setIsPost(true);
  };

  const currentUrl = window.location.href;
  const urlSlug = currentUrl.split("/create/")[1];

  const verRef = useRef(null);
  const healthRef = useRef(null);

  const scrollToVer = () => {
    if (verRef.current) {
      verRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  const scrollToHealt = () => {
    if (healthRef.current) {
      healthRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  const [data, setData] = useState({});

  const [formData, setFormData] = useState({});

  const h1title = formData?.name
    ? t("editad") + " " + formData?.name
    : t("createnewad");

  const [cities, setCities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [services, setServices] = useState([]);

  const [isMainLoading, setIsMainLoading] = useState(false);

  const [allSteps, setAllSteps] = useState(false);

  const [isProfileAlreadyCreate, setIsProfileAlreadyCreate] = useState(false);

  const rootRef = useRef(null);

  const isOpenWithProblem =
    !eliteGlobal || !formData?.healthy_at || !formData.verified_at;

  const urlParams = window.location.hash.substring(1);

  const allowedValues = ["photo", "videofotograph", "interior", "erocontent"];

  const [visualElem, setVisualElem] = useState(
    allowedValues.includes(urlParams) ? urlParams : "photo"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `services/all`;
        const method = "GET";
        const payload = {lang};

        const {cities, languages, nationalities, services} =
          await makeRequest({route, method, payload});
        setServices(services);
        setNationalities(nationalities);
        setLanguages(languages);
        setCities(cities);
      } catch (error) {
      }
    };

    token && lang && fetchData();
  }, [token, lang]);

  const [changeCityDisable, setChangeCityDisable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsMainLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const payload = {
          slug: urlSlug,
        };

        const data = await makeRequest({route, method, payload});

        if (!data.profiles.length) {
          navigate('404')
        }

        if (data) {
          const result = data.profiles;
          setIsProfileAlreadyCreate(true);
          setFormData(result[0] || {});
          if (false) {
            setChangeCityDisable(true);
          }
          setAvailableSteps((prev) => [...prev, 2]);
          if (!!result[0].main_photo || !!result[0].media.photo.length) {
            setAvailableSteps((prev) => [...prev, 3]);
          }
          setFilled(result[0].completion);
          setAllSteps(
            !!result[0].slug &&
            (!!result[0].main_photo || !!result[0].media.photo.length)
          );
          setStatusAd(result[0].is_enabled);
          setEliteGlobal(result[0].is_elite);
          dispatch(setOneProfileAddress(result[0].address));
          dispatch(
            setOneProfileCoords({
              latitude: result[0]?.latitude,
              longitude: result[0]?.longitude,
            })
          );
        }
        setIsMainLoading(false);
      } catch (error) {
      }
    };

    !!urlSlug && !Object.values(formData)?.length && !!lang && fetchData();
  }, [urlSlug, formData]);
  // [urlSlug, lang, formData]
  const backToProfiles = (e) => {
    e.preventDefault();

    navigate(`/lk/indi/profiles`);
  };

  const onPost = async (e) => {
    e.preventDefault();
    try {
      const route = `user/profile/${formData.slug}/set-status`;
      const method = "PUT";
      const payload = {
        status: true,
      };

      await makeRequest({route, method, payload});

      !formData.is_enabled && setStatusAd((prev) => !prev);

      navigate(`/lk/indi/profiles`);

      showToast({
        message: `${t("ad")} ${formData.name} ${t("publishedone")}`,
        variant: "success",
      });
    } catch (error) {
      setStatusAd((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const [dataInfo, setDataInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `user/info`;

        const method = "GET";

        const data = await makeRequest({route, method});

        if (data) {
          const result = data.data;
          setDataInfo(result);
          dispatch(setElitePrice({
            oldPrice: result.old_elite_price,
            currentPrice: result.elite_price
          }))
        }
      } catch (error) {
      }
    };

    currentStep === 6 && fetchData();
  }, [currentStep])

  const [isVerificationExist, setIsVerificationExist] = useState(false);
  const [isBoosted, setIsBoosted] = useState(false);

  useEffect(() => {
    if (formData.verify?.status === 1 && formData.verify?.boost === 1) {
      setIsVerificationExist(true);
      setIsBoosted(true)
    }
  }, [formData])

  return userType === 'indi' || userType === 'agency' || userType === 'admin' ? (
    <main>
      <section className={"profile-page__container"}>

        <div className="navigation__top">
          <h1>{h1title}</h1>

          <ButtonLink
            clazz={`button_outline--black _no-underline display-none-mobile`}
            size={"l"}
            href={"/lk/indi/profiles"}
            onClick={(e) => backToProfiles(e)}
          >
            {t("cancel")}
          </ButtonLink>
        </div>

        {!isMainLoading ? (
          <>
            <div ref={rootRef} className={"create-page__root"}>
              <Steps
                availableSteps={availableSteps}
                step={currentStep}
                changeStep={setCurrentStep}
                onClick={(e) => backToProfiles(e)}
                filled={filled}
                allSteps={allSteps}
                rootElem={rootRef.current}
                setVisualElem={setVisualElem}
              />

              <div className={"create-page__main"}>
                {currentStep === 1 && (
                  <MainGlobal
                    setAvailableSteps={setAvailableSteps}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    nationalities={nationalities}
                    languages={languages}
                    changeStep={setCurrentStep}
                    cities={cities}
                    setData={setData}
                    isProfileAlreadyCreate={isProfileAlreadyCreate}
                    setIsProfileAlreadyCreate={setIsProfileAlreadyCreate}
                    setFilled={setFilled}
                    changeCityDisable={changeCityDisable}
                  />
                )}
                {currentStep === 2 && (
                  <MediaGlobal
                    setAvailableSteps={setAvailableSteps}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    step={currentStep}
                    changeStep={setCurrentStep}
                    isProfileAlreadyCreate={isProfileAlreadyCreate}
                    setIsProfileAlreadyCreate={setIsProfileAlreadyCreate}
                    setFilled={setFilled}
                    visualElem={visualElem}
                    setVisualElem={setVisualElem}
                    setAllSteps={setAllSteps}
                  />
                )}
                {currentStep === 3 && (
                  <AppearanceGlobal
                    setAvailableSteps={setAvailableSteps}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    step={currentStep}
                    changeStep={setCurrentStep}
                    setFilled={setFilled}
                  />
                )}
                {currentStep === 4 && (
                  <ServicesGlobal
                    setAvailableSteps={setAvailableSteps}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    step={currentStep}
                    services={services}
                    changeStep={setCurrentStep}
                    setFilled={setFilled}
                  />
                )}
                {currentStep === 5 && (
                  <TarifsGlobal
                    setAvailableSteps={setAvailableSteps}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    step={currentStep}
                    changeStep={setCurrentStep}
                    setFilled={setFilled}
                  />
                )}
                {currentStep === 6 && (
                  <Confirmation
                    isBoosted={isBoosted}
                    setIsBoosted={setIsBoosted}
                    setIsVerificationExist={setIsVerificationExist}
                    isVerificationExist={isVerificationExist}
                    scrollToVer={scrollToVer}
                    scrollToHealt={scrollToHealt}
                    formData={formData}
                    setFormData={setFormData}
                    rootElem={rootRef.current}
                    step={currentStep}
                    changeStep={setCurrentStep}
                    nationalities={nationalities}
                    eliteGlobal={eliteGlobal}
                    setEliteGlobal={setEliteGlobal}
                    statusAd={statusAd}
                    setStatusAd={setStatusAd}
                    setVisualElem={setVisualElem}
                  />
                )}
              </div>
            </div>
            {currentStep === 6 && (
              <>
                <StatusElite
                  eliteGlobal={eliteGlobal}
                  setEliteGlobal={setEliteGlobal}
                  formData={formData}
                />
                <VerificationAndHealthCheck
                  isBoosted={isBoosted}
                  setIsBoosted={setIsBoosted}
                  setIsVerificationExist={setIsVerificationExist}
                  isVerificationExist={isVerificationExist}
                  isCreatePage={true}
                  verRef={verRef}
                  healthRef={healthRef}
                  data={dataInfo}
                  setDataInfo={setDataInfo}
                />
                <CountriesFilter setFormData={setFormData} formData={formData} data={data} isModel={true}/>
                <div className="create-form__buttons">
                  <Button
                    // onClick={isOpenWithProblem ? handleOpenPost : onPost}
                    onClick={onPost}
                    size={"l-forever"}
                    square={false}
                    clazz="button--green min-w-160"
                  >
                    {formData.slug
                      ? t("save") +
                      " " +
                      t("and") +
                      " " +
                      t("publish").toLowerCase()
                      : t("publish")}
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <Loader/>
        )}

        {isPost &&
          <PostAdPopup
            open={isPost}
            setOpen={setIsPost}
            slug={formData.slug}
            name={formData.name}
            status={formData.is_enabled}
            elite={!eliteGlobal}
            helth={!formData?.healthy_at}
            ver={!formData.verified_at}
          />
        }
        <ChangePhoto/>

      </section>
    </main>
  ) : <Loader/>;
};

export default CreateProfile;
