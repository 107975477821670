import {
  setAuthorizationStatus,
  setStoriesMap,
} from "@/stores/slices/popupSlice";
import React from "react";
import { Button, ButtonLink, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useSelector } from "react-redux";
import {
  getFavoriteCounterStatus,
} from "@/stores/slices/userSlice";
import { show, showShort } from "@/stores/slices/showFilterSlice";
import { getProfilesCount } from "@/stores/slices/mapModelSlice";
import {
  setBurgerMenu,
} from "@/stores/slices/headerSlice";

const MenuNavBar = ({lk}) => {
  const { dispatch, userType, t, navigate, userBalance, city } = useLadyService();

  const showFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(show());
  };

  const showShortFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(showShort());
  };

  const favoriteCount = useSelector(getFavoriteCounterStatus);
  const profilesCountValue = useSelector(getProfilesCount);

  const toLk = (key) => {
    navigate(`/lk/${key}`);
  };

  const headerIconsMenuBar = {
    agency_lk: [
      {
        id: 1,
        icon: "user-circle",
        name: t("profuser"),
        link: "lk/agency",
      },
      {
        id: 2,
        icon: "user-id",
        name: t("myads"),
        link: "#",
      },
      {
        id: 3,
        icon: "star",
        name: t("reviews"),
        link: "#",
      },
      {
        id: 5,
        icon: "stat",
        name: t("mystat"),
        link: "#",
      },
      {
        id: 6,
        name: t("balance"),
        link: "#",
        wallet: userBalance,
        icon: "wallet",
      },
      {
        id: 7,
        icon: "camera",
        name: t("photographer"),
        link: "/lk/photographer",
      },
    ],
    indi_lk: [
      {
        id: 1,
        icon: "user-circle",
        name: t("profuser"),
        link: "/lk/indi",
      },
      {
        id: 2,
        icon: "user-id",
        name: t("myads"),
        link: "/lk/indi/profiles",
      },
      {
        id: 3,
        icon: "star",
        name: t("reviews"),
        link: "/lk/indi/reviews",
      },
      {
        id: 4,
        icon: "stat",
        name: t("mystat"),
        link: "/lk/indi/statistic",
      },
      {
        id: 5,
        name: t("balance"),
        link: "/lk/balance",
        wallet: userBalance,
      },
      {
        id: 6,
        icon: "undo-left",
        name: t("backtomain"),
        link: `/${city}`,
      },
    ],
    default: [
      {
        id: 1,
        icon: "home-main",
        name: t("mainpage"),
        link: "/",
      },
      {
        id: 2,
        icon: "catalog",
        name: t("catalogue"),
        onClick: () => showShortFilter(),
      },
      {
        id: 3,
        icon: "filter",
        name: t("filter"),
        onClick: () => showFilter(),
      },
      {
        id: 4,
        icon: "map",
        name: t("onmap"),
        onClick: () => profilesCountValue && dispatch(setStoriesMap(true)),
        disabled: profilesCountValue === 0,
      },
      {
        id: 5,
        icon: "favorite",
        clazz: `user__favorite${favoriteCount <= 0 ? ' empty' : ''}`,
        name: t("favorites"),
        counter: favoriteCount,
        link: "/history",
      },
      {
        id: 6,
        icon: "person",
        name: t("profuser"),
        onClick:
          userType === "default"
            ? () => dispatch(setAuthorizationStatus(true))
            : () => toLk(userType),
      },
    ],
  };
  const MenuNavBarLinksByUserType = () => {
    switch (userType) {
      case "agency":
        return headerIconsMenuBar.agency_lk;
        break;

      case "indi":
        return lk ? headerIconsMenuBar.indi_lk : headerIconsMenuBar.default;
        break;

      default:
        return headerIconsMenuBar.default;
    }
  };

  return (
    <nav className="menu__body">
      {MenuNavBarLinksByUserType()
        .slice(0, 6)
        .map((i) => {
          if (i.link) {
            return (
              <ButtonLink
                href={i.link}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz={`menu__link _no-underline${
                  i.clazz ? ` ${i.clazz}` : ""
                }`}
                counter={i.wallet ? i.counter : ""}
              >
                {i.icon === 'wallet' ? (
                  <span className={"menu__wallet currency--erocoin"}>
                    {i.wallet}
                  </span>
                ) : (
                  <Icon
                    counter={i.counter}
                    size={"l"}
                    clazz={i.clazz}
                    spritePath={i.icon}
                  />
                )}
                <span>{i.name}</span>
              </ButtonLink>
            );
          }

          return (
            <Button
              onClick={i.onClick}
              key={i.id}
              title={i.name}
              size={"l"}
              counter={i.counter}
              clazz={`menu__link${i.clazz ? ` ${i.clazz}` : ""}`}
              disabled={i.disabled}
            >
              <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
              <span>{i.name}</span>
            </Button>
          );
        })}
    </nav>
  );
};

export default MenuNavBar;
