import moment from "moment/moment";
import {
  Button,
  Checkbox,
  ErrorMessage,
  Icon,
  Input,
  InputInLabel,
  Select,
  SelectCountry
} from "@/components/ui";
import GeoInput from "../../../../../../../ui/input/GeoInput";
import Map from "../../../map/Map";
import useLadyService from "@/services/LadyService";

const MainPart = ({

                    register,
                    errors,
                    createProfileSlug,
                    checkNickIsAvailable,
                    nickValue,
                    setAgeValue,
                    formData,
                    phoneValue,
                    setPhoneValues,
                    isLoading,
                    setError,
                    waValue,
                    setWaValues,
                    setNationality,
                    transformedCountries,
                    sortByName,
                    nationality,
                    setEthnos,
                    options,
                    setReadyTravel,
                    handleLangOpen,
                    langPopup,
                    langsError,
                    selectRef,
                    searchValue,
                    setSearchValue,
                    data,
                    langsCode,
                    toggleLang,
                    languages,
                    sortedLanguages,
                    langs,
                    removeLang,
                    citiesOptions,
                    cityError,
                    formCity,
                    selectedCity,
                    setSelectedCity,
                    currentActive,
                    setActiveCountry,
                    activeCountry,
                    setCurrentActive,
                    throwError,
                    address,
                    geoError,
                    bounds,
                    handleLocationClick,
                    isAddressActive,
                    setBounds,
                    mapMarker,
                    setIsAddressActive,
                    clearErrors,
                    setCityError,
                    setTg,
                    setName,
                    name,
                    tg,
                    setAge,
                    changeCityDisable,
                    isModal = false,
                  }) => {
  const {t, windowWidth} = useLadyService();
  const originalDate = moment.unix(formData.is_new_at).utc();

  const dateAfter7Days = originalDate.add(7, 'days');

  const formattedDate = dateAfter7Days.format("DD.MM.YYYY");

  function getCountriesByIcon(arr) {
    const iconsToFind = ["CZ", "EU", "RU", "UA"];
    return arr.filter(country => iconsToFind.includes(country.icon));
  }

  return (
    <div className={`create-page__form main`}>
      <InputInLabel
        type={"text"}
        id={"create-name"}
        placeholder={""}
        value={name}
        required
        register={{...register("name")}}
        clazz={errors?.name ? "_error" : ""}
        onChange={(e) => {
          if (errors?.name) {
            clearErrors("name");
          }
          setName(e.target.value);
          createProfileSlug(e);
        }}
      >
        {t("nameofad")}
        {errors?.name && <ErrorMessage message={t("required")}/>}
      </InputInLabel>

      <InputInLabel
        type={"text"}
        id={"create-nick"}
        placeholder={"https://lady4love.com/Vash-Nic"}
        register={{...register("nick")}}
        required
        clazz={errors.nick ? "_error" : ""}
        onChange={(e) => {
            checkNickIsAvailable(e.target.value)

        }}
        value={nickValue}
      >
        {t("nick")}
        {errors.nick && <ErrorMessage message={errors.nick.message}/>}
      </InputInLabel>

      <InputInLabel
        type={"number"}
        id={"create-age"}
        placeholder={"18-99"}
        required
        register={{...register("age")}}
        clazz={errors.age ? "_error" : ""}
        onChange={(e) => {
          setAge(Number(e.target.value));
          setAgeValue(e);
        }}
        value={formData.age}
      >
        {t("age")}
        {errors.age && <ErrorMessage message={errors.age.message}/>}
      </InputInLabel>

      <InputInLabel
        type={"tel"}
        id={"create-phone"}
        placeholder={"+XXX XXX XXX XXX"}
        register={{...register("phone")}}
        autocomplete={"tel"}
        required
        clazz={errors.phone?.message ? "_error" : ""}
        maskValue={phoneValue}
        setMaskValue={setPhoneValues}
        valueLoad={isLoading}
        setError={setError}
        errorType={"phone"}
        clearErrors={clearErrors}
        isModal={isModal}
      >
        {t("phonenumber")}
        {errors.phone && <ErrorMessage message={errors.phone.message}/>}
      </InputInLabel>

      <InputInLabel
        type={"tel"}
        id={"create-whatsapp"}
        placeholder={""}
        clazz={errors.whatsapp?.message ? "_error" : ""}
        register={{...register("whatsapp")}}
        autocomplete={"whatsapp"}
        maskValue={waValue}
        setMaskValue={setWaValues}
        setError={setError}
        errorType={"whatsapp"}
        clearErrors={clearErrors}
      >
        {"Whatsapp"}
        {errors.whatsapp && <ErrorMessage message={errors.whatsapp.message}/>}
      </InputInLabel>

      <InputInLabel
        type={"text"}
        id={"create-telegram"}
        placeholder={""}
        register={{...register("telegram")}}
        autocomplete={"telegram"}
        value={formData?.telegram || tg}
        onChange={(e) => setTg(e.target.value)}
      >
        {t("telegram")}
      </InputInLabel>

      <Select
        onChange={setNationality}
        name={"create-nationalist"}
        iconSize={'m'}
        optionsDefault={getCountriesByIcon(transformedCountries?.length > 0 && transformedCountries)}
        options={transformedCountries?.length > 0 && sortByName(transformedCountries)}
        clazz={"size-l-forever"}
        clazzSvg={"select__flag"}
        clazzText={'text-dots'}
        arrowSize={"m"}
        isSearch={true}
        searchPlaceHolder={t("searchfield")}
        type={"flag"}
        checkbox
        placeholder={t("nationality")}
        defaultTitle={nationality}
        withoutTitle={true}
        postApi={true}
        hideFlag={!!nationality}
        withoutIcon={!!nationality}
      />

      <Select
        clazz={"size-l-forever"}
        onChange={setEthnos}
        name={"create-ethnos"}
        options={options.ethnos}
        arrowSize={"m"}
        placeholder={t("ethnicity")}
        defaultTitle={
          formData.skin_color
            ? options.ethnos.filter(
              (item) => item.value === formData.skin_color
            )[0]?.title
            : ""
        }
        withoutTitle={true}
        postApi={true}
      />

      <Select
        clazz={"size-l-forever"}
        onChange={setReadyTravel}
        name={"create-readyTravel"}
        options={options.readyTravel}
        arrowSize={"m"}
        placeholder={t("travel")}
        defaultTitle={
          formData.ready_travel
            ? options.readyTravel.filter(
              (item) => item.value === formData.ready_travel
            )[0]?.title
            : ""
        }
        withoutTitle={true}
        postApi={true}
      />

      <div className={`select _required${langPopup ? " select_active" : ""}`}>
        <button
          type={"button"}
          onClick={() => handleLangOpen()}
          id={'select-lang-button'}
          className={`select__button size-l-forever`}
        >
          <span className={"select__placeholder"}>{t("languages")}</span>
          <Icon
            clazz={"select__arrow"}
            size={"m"}
            spritePath={"chevrone-down"}
          />
          {langsError && <ErrorMessage message={langsError}/>}
        </button>

        {langPopup && (
          <div
            ref={selectRef}
            className={`select__body gap-8 w-360`}
            id={"select-lang"}
          >
            <label htmlFor="citiesSearch" className="select__search">
              <Input
                type="search"
                placeholder={t("searchfield")}
                id="citiesSearch"
                clazz={"search__input"}
                name={"create-cities"}
                value={searchValue}
                setValue={setSearchValue}
              />
            </label>

            <div className="d-flex justify-sb gap-8">
              {data.standLang.map((i) => {
                return (
                  <Checkbox
                    clazz={'button--tetriary'}
                    flag={i.short_name.toUpperCase()}
                    key={i.short_name.toUpperCase()}
                    name={i?.name}
                    id={i.short_name.toUpperCase()}
                    value={i?.name}
                    title={i.short_name.toUpperCase()}
                    checked={langsCode.includes(i.short_name)}
                    onChange={() =>
                      toggleLang({
                        id: i.id,
                        short_name: i.short_name,
                        name: i?.name,
                      })
                    }
                  />
                );
              })}
            </div>

            <div className="dropdown__search-select gap-8">
              {languages?.filter((item) =>
                item?.name.toLowerCase().includes(searchValue.toLowerCase())
              ).length > 0 ? (
                sortedLanguages
                  .filter((item) =>
                    item?.name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((item) => (
                    <Checkbox
                      border={false}
                      flag={item.short_name.toUpperCase()}
                      key={item.short_name.toUpperCase()}
                      name={item?.name}
                      id={item.id}
                      value={item.short_name}
                      title={item?.name}
                      textClazz={'text-dots max-w-none'}
                      checked={
                        !!langsCode.includes(item.short_name)
                      }
                      onChange={() => toggleLang(item)}
                    />
                  ))
              ) : (
                <span className="p2">{t("nolanguages")}</span>
              )}
            </div>
          </div>
        )}
      </div>

      {!!langs?.length && (
        <div className="create-lang--current">
          {langs.map((lang) => {
            return (
              <div
                key={lang?.name}
                className="button button-l-forever button--secondary bg-color-200 color-600"
              >

                <Icon size={"m"} type={'flag'} spritePath={lang?.short_name?.toUpperCase()}/>

                {lang?.name}

                <button
                  className={'icon icon-m icon--close bg-none button__close'}
                  onClick={() => removeLang(lang?.short_name)}
                >
                </button>
              </div>
            );
          })}
        </div>
      )}

      <SelectCountry
        data={citiesOptions}
        name={"city-create"}
        clazzWrapper={"select"}
        clazz={`size-l-forever required ${cityError ? "_error" : ""} ${
          changeCityDisable || !!formData.is_new_at ? " disabled _disabled" : ""
        } grid-col-1`}
        clazzSvg={`select__flag ${formCity.icon}`}
        arrowSize={"m"}
        type={"flag"}
        placeholder={t("yourcity")}
        defaultTitle={selectedCity || formCity?.name}
        withoutIcon={!selectedCity}
        setValue={setSelectedCity}
        currentActiveIndex={currentActive}
        setActiveCountry={setActiveCountry}
        activeCountry={activeCountry}
        setActive={setCurrentActive}
        error={cityError}
        setError={setCityError}
        setIsAddressActive={setIsAddressActive}
        errorMessage={<ErrorMessage message={cityError}/>}
      />

      {windowWidth && windowWidth < 767 && (
        <span className="create-page__noted grid-col-1">
           {!formData.is_new_at && `${t("important")}! ${t("i-changecityper")}`}
          {formData.is_new_at && (
            <span>
              {t("changecityav")}{" "}
              {formattedDate}
            </span>
          )}
        </span>
      )}

      <div className={`search filter__map-input`}>
        <div
          className={`search filter__map-input ${
            selectedCity ? "" : "_disabled"
          }`}
          onClick={(e) => throwError(e)}
        >
          <span className={"filter__map-title"}>{t("youraddress")}</span>
          <label
            htmlFor="geosuggest__input-create"
            className="search__label header__search"
          >
            <GeoInput
              value={address[0]}
              name={"location-create"}
              id="geosuggest__input-create"
              clazz={`header__search-input ${geoError ? "_error" : ""}`}
              placeholder={t("fulladdress")}
              bounds={bounds}
            />

            {geoError && <ErrorMessage message={t("cityfirst")}/>}
          </label>
        </div>
        <Button
          title={t("chekmyplace")}
          onClick={handleLocationClick}
          name={"location"}
          square={true}
          size={"l"}
          clazz={
            isAddressActive
              ? "search__icon button--tetriary active"
              : "search__icon button--tetriary"
          }
        >
          <Icon size={"xl"} spritePath={"location"}/>
        </Button>
      </div>

      {windowWidth > 767 && (
        <span className="create-page__noted grid-col-1">
          {!formData.is_new_at && `${t("important")}! ${t("i-changecityper")}`}
          {formData.is_new_at && (
            <span>
              {t("changecityav")}{" "}
              {formattedDate}
            </span>
          )}
        </span>
      )}
      <span className="create-page__noted grid-col-2">
        {t("displayonsite")}
        <span className="create-page__noted-colored">
          {" "}
          +<span>35</span>% {t("toviews")}, +<span>15</span>% {t("toorders")}
        </span>
      </span>
      {selectedCity && (
        <Map
          setMarkerActive={setIsAddressActive}
          markerActive={isAddressActive}
          region={activeCountry}
          city={selectedCity}
          setBounds={setBounds}
          coords={mapMarker}
        />
      )}
    </div>
  );
};

export default MainPart