import React, { useState } from "react";
import useLadyService from "@/services/LadyService";
import { Checkbox, Icon, Tippy } from "../../../../ui";

export const AgencyRowWrapper = ({
  children,
  setActiveAddress,
  activeAddress,
  index,
  isActive,
}) => {
  const { userType, t } = useLadyService();

  if (userType === "agency") {
    return (
      <>
        <div className="d-flex gap-8 align-center">
          <Checkbox
            checked={activeAddress === index}
            id={index}
            title={t("main")}
            disabled={!isActive}
            onClick={() => {
              setActiveAddress(index);
            }}
          />
          <Tippy title={t("adressvisible")}>
            <Icon square size={"m"} spritePath={"info"} clazz={"mt-4"} />
          </Tippy>
        </div>

        {children}
      </>
    );
  } else {
    return <>{children}</>;
  }
};
