import Navigation from "../navigation/Navigation";
import GirlMap from "../girlMap/GirlMap";
import AboutService from "../aboutService/AboutService";
import BenefitsBlock from "../benefitsBlock/BenefitsBlock";
import StoriesComponent from "../stories/StoriesComponent";
import { useDispatch, useSelector } from "react-redux";
import { getStories } from "@/stores/slices/modelsState";
import { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";
import { setVideoCallRoom } from "@/stores/slices/videoCallSlice";
import { useParams } from "react-router-dom";
import { getProfilesCount } from "@/stores/slices/mapModelSlice";
import useTitle from "@/hooks/useTitle";
import { getUserCurrentCity, getUserRegion } from "@/stores/slices/userSlice";
import { getTitle } from "@/stores/slices/titleSlice";
import { Helmet } from "react-helmet";
import { getAllSorted } from "../../stores/slices/modelsState";
import {filterPlace, getFastFilters, isFilterSearch} from "../../stores/slices/filterSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const { t, userCity } = useLadyService();
  const { room } = useParams();
  const stories = useSelector(getStories);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const profilesCountValue = useSelector(getProfilesCount);
  const currentCity = useSelector(getUserCurrentCity);
  const title = useSelector(getTitle);
  const region = useSelector(getUserRegion);

  useEffect(() => {
    if (room) {
      console.log("main", room);
      dispatch(setVideoCallRoom({ room_id: room }));
    }
  }, [dispatch, room]);

  const postTitle = t("title")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : ""
  );
  const titleString = !!title.length
    ? title
        .filter((item) => !!item)
        .map((item) => t(item))
        .join(", ")
    : "";

  const titleParams = titleString
    ? `${userCity.title} - ${t("titleselect")}: ${titleString}`
    : postTitle;

  useTitle(titleParams);
  let postDesc = t("description")?.replace(/%([^%]+)%/g, (match, p1) => {
    switch (p1) {
      case "City":
        return currentCity.title;
      case "Country":
        return region;
      default:
        return match;
    }
  });
  if (titleString) {
    const descCity = t("incity")?.replace(
        /%([^%]+)%/g,
        currentCity.title ? currentCity.title : ""
    );
    postDesc = `${t('titleselect')}: ${titleString} - ${descCity}`
  }

  const valueNavigation = useSelector(getAllSorted);
  const valuePlace = useSelector(filterPlace);
  const isFilterValue = useSelector(isFilterSearch);
  const fastFilters = useSelector(getFastFilters);
  const [isRenderAbout, setIsRenderAbout] = useState(!fastFilters);

  useEffect(() => {
    const isRes = !!valueNavigation.length || !!valuePlace.length || isFilterValue;
    setIsRenderAbout(!isRes);
  }, [valueNavigation, isFilterValue, valuePlace]);

  return (
    <>
      <main>
        <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet>
        <Navigation setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} />
        {/*<NewGirlsSlider />*/}
        {!isDataEmpty && !!profilesCountValue && <GirlMap />}
        {isRenderAbout && <AboutService />}
        {/*<BestCityAgencySlider />*/}
        {isRenderAbout && <BenefitsBlock/>}
      </main>

      {!!stories.length && (
        <StoriesComponent
          favoriteButton
          descriptionText
          profileLink
          stories={stories}
        />
      )}
    </>
  );
};

export default MainPage;
